import { useEOSSContext } from 'context/EOSSContext';
import { EDeviceType, EHttpStatusCode, Routes } from 'enums';
import type {
  TAccount,
  TAccountType,
  TContact,
  TDevice,
  TDeviceSummary,
  TDeviceType,
  TPaginationEOSS,
  TValidation
} from 'models';
import { useEffect, useRef, useState } from 'react';
import { AccountRepository } from 'repositories';
import type { TPutAccountsRequest } from 'repositories/Account/AccountRepository';
import { AxiosClient } from 'services/axios';
import { handleApiError, isJsonString } from 'utils/common';
import {
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useAppUtil } from 'context/UtilContext';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { ApiErrorResponse } from 'models/ApiError';
import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';

export const useAccountDetailController = () => {
  const navigate = useNavigate();
  const accountRepository = AccountRepository(AxiosClient);
  const currentAccount = useLoaderData() as TAccount;
  const { listValidation, listAccountType, listDeviceType, refetchListAccountType } =
    useEOSSContext();
  const [searchParams] = useSearchParams();

  const isFirstLoad = useRef<boolean>(true);

  const refEl = useRef<HTMLDivElement | null>(null);

  const [form] = useForm();
  const [billingForm] = useForm();

  const searchKey = searchParams.get('search');

  const { openNotification } = useAppUtil();
  const { t } = useTranslation();

  const { accountId } = useParams<{ accountId: string }>();
  const { actionStatus, handleClearForm, handleChangeAccount, onResetActionStatus } =
    useOutletContext<{
      actionStatus: {
        type: 'assign' | null;
        success: boolean;
      };
      handleClearForm: () => void;
      handleChangeAccount: (value?: TAccount) => void;
      onResetActionStatus: () => void;
    }>();

  const [devicesSummaryList, setDevicesSummaryList] = useState<{
    data: TDeviceSummary[];
    loading: boolean;
    page?: TPaginationEOSS;
  }>({
    data: [],
    loading: false
  });

  const [serviceDeviceList, setServiceDeviceList] = useState<{
    data: TDevice[];
    loading: boolean;
    page?: TPaginationEOSS;
    order?: {
      columnKey: string;
      descend: boolean;
    };
  }>({
    data: [],
    loading: false,
    page: {
      pageLimit: DEFAULT_LIMIT,
      pageNum: DEFAULT_PAGE_NUMBER,
      total: 0,
      totalPage: 0
    }
  });

  const [deviceTypeSelected, setDeviceTypeSelected] = useState<TDeviceType>(listDeviceType[0]);

  /**
   * TODO: Implement as soon as API getDeviceReferenceList available
   */
  const [deviceReferenceList, setDeviceReferenceList] = useState<{
    data: Array<{ id: number; serialNumber: string }>;
    loading: boolean;
    page?: TPaginationEOSS;
  }>({
    data: [],
    loading: false
  });

  const handleUpdateAccount = async (data: TPutAccountsRequest) => {
    try {
      const { code } = await accountRepository.editAccount(data);
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.update')} ${t('accountPage.entity')}`,
          description: `${t('components.success')}`
        });
        navigate(
          `${Routes.EAccountRoutes.LISTING}${searchKey ? `?search=${searchKey}&page=1` : ''}`
        );
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'update',
        entity: t('accountPage.entity'),
        t,
        identifier: `ID ${data.id.toString()}`
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('accountPage.entity')}`,
        description: message
      });
    }
  };

  const handleFetchListDevicesSummary = async (id: number) => {
    if (devicesSummaryList.loading) return;
    setDevicesSummaryList((prev) => ({
      ...prev,
      loading: true
    }));
    try {
      const { code, data, page } = await accountRepository.getDevicesSummaryByAccountId(id);
      if (code === EHttpStatusCode.OK) {
        setDevicesSummaryList((prev) => ({
          ...prev,
          loading: false,
          data,
          paging: page ?? prev.page
        }));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.sections.devicesSummary.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('accountPage.sections.devicesSummary.entity')}`,
        description: message
      });
      setDevicesSummaryList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleFetchListServiceDevice = async ({
    deviceTypeSelected,
    order,
    pageNumber,
    callback
  }: {
    deviceTypeSelected: EDeviceType;
    order?: {
      columnKey: string;
      descend: boolean;
    };
    pageNumber: number;
    callback?: () => void;
  }) => {
    if (serviceDeviceList.loading) return;
    setServiceDeviceList((prev) => ({
      ...prev,
      loading: true
    }));
    try {
      const { code, data, page } = await accountRepository.getServiceDeviceList({
        accountName: currentAccount.name,
        deviceTypeName: deviceTypeSelected,
        page: pageNumber,
        order: order ? (order?.descend ? 'descend' : 'ascend') : null,
        sortKey: order && order.columnKey
      });
      if (code === EHttpStatusCode.OK) {
        setServiceDeviceList({ loading: false, data, page, order });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.sections.serviceDevice.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('accountPage.sections.serviceDevice.entity')}`,
        description: message
      });
      setServiceDeviceList((prev) => ({
        ...prev,
        loading: false
      }));
    } finally {
      callback && callback();
      isFirstLoad.current = false;
    }
  };

  const handleDeviceTypeChange = (value: TDeviceType) => setDeviceTypeSelected(value);

  const handleFetchDeviceReference = async (id: number) => {
    if (deviceReferenceList.loading) return;
    setDeviceReferenceList((prev) => ({
      ...prev,
      loading: true
    }));
    try {
      const { code, data } = await accountRepository.getDeviceReferenceListByAccountId(id);
      if (code === EHttpStatusCode.OK) {
        // TODO: Paging here
        setDeviceReferenceList({ loading: false, data });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.sections.referenceDevice.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('accountPage.sections.referenceDevice.entity')}`,
        description: message
      });
      setDeviceReferenceList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleCancelClick = () =>
    navigate(
      { pathname: Routes.EAccountRoutes.LISTING, search: searchParams.toString() },
      { replace: true }
    );

  const handleServiceDeviceChangeSort = (order?: string | null, columnKey?: string) => {
    if (columnKey && order) {
      setServiceDeviceList((prev) => ({
        ...prev,
        order: {
          columnKey,
          descend: order === 'descend'
        }
      }));
    } else {
      setServiceDeviceList((prev) => ({
        ...prev,
        order: undefined
      }));
    }
  };

  const handlePageNumberChange = (value: number) => {
    handleFetchListServiceDevice({
      deviceTypeSelected: deviceTypeSelected.name as EDeviceType,
      pageNumber: value,
      order: serviceDeviceList.order
    });
  };

  useEffect(() => {
    if (currentAccount) {
      handleChangeAccount(currentAccount);
      const contacts = isJsonString(currentAccount.contact.toString())
        ? (JSON.parse(currentAccount.contact.toString()) as TContact[])
        : [];
      const address = isJsonString(currentAccount.address.toString())
        ? JSON.parse(currentAccount.address.toString())
        : {
            street: currentAccount.address.toString()
          };
      form.setFieldValue('accountNumber', currentAccount.accountNumber);
      form.setFieldValue('name', currentAccount.name);
      form.setFieldValue('domainId', currentAccount.domain.name);
      form.setFieldValue('street', address.street);
      form.setFieldValue('city', address?.city);
      form.setFieldValue('state', address?.state);
      form.setFieldValue('zip', address?.zip);
      form.setFieldValue('url', currentAccount.url);
      form.setFieldValue('comment', currentAccount.comment);
      form.setFieldValue('invalidId', currentAccount.invalid);
      form.setFieldValue(
        'contacts',
        contacts.length > 0 ? contacts : [{ name: '', email: '', phone: '' }]
      );
      currentAccount.billingStartAt &&
        billingForm.setFieldValue(['billing', 'start'], dayjs.unix(currentAccount.billingStartAt));
      currentAccount.billingEndAt &&
        billingForm.setFieldValue(['billing', 'stop'], dayjs.unix(currentAccount.billingEndAt));
      billingForm.setFieldValue(['billing', 'discount'], currentAccount.discountRate);
      billingForm.setFieldValue(['billing', 'stateTax'], currentAccount.stateTax);
      billingForm.setFieldValue(['billing', 'localTax'], currentAccount.localTax);
    }
  }, [currentAccount]);

  useEffect(() => {
    if (
      accountId &&
      Number.isInteger(Number(accountId)) &&
      !accountId.includes('.') &&
      listAccountType.length > 0
    ) {
      const accountID = Number(accountId);
      Promise.allSettled([
        refetchListAccountType(),
        handleFetchListDevicesSummary(accountID),
        handleFetchListServiceDevice({
          deviceTypeSelected: listDeviceType[0].name as EDeviceType,
          pageNumber: DEFAULT_PAGE_NUMBER
        }),
        handleFetchDeviceReference(accountID)
      ]);
    }
  }, [accountId, listAccountType]);

  useEffect(() => {
    if (listAccountType.length > 0) {
      const accountType = listAccountType.find(
        (accountType: TAccountType) =>
          Number(accountType?.id) === Number(currentAccount?.accountType?.id)
      );

      if (accountType) {
        form.setFieldValue('accountTypeId', `${accountType?.description} (${accountType?.name})`);
      } else {
        form.setFieldValue('accountTypeId', currentAccount?.accountType?.name);
      }
    }
  }, [listAccountType, currentAccount]);

  /**
   * TAB CHANGE
   */
  useEffect(() => {
    if (deviceTypeSelected && !isFirstLoad.current) {
      handleFetchListServiceDevice({
        deviceTypeSelected: deviceTypeSelected.name as EDeviceType,
        order: undefined,
        pageNumber: DEFAULT_PAGE_NUMBER
      });
    }
  }, [deviceTypeSelected]);

  /**
   * SORT CHANGE
   */
  useEffect(() => {
    if (deviceTypeSelected && !isFirstLoad.current) {
      handleFetchListServiceDevice({
        deviceTypeSelected: deviceTypeSelected.name as EDeviceType,
        order: serviceDeviceList.order,
        pageNumber: serviceDeviceList.page?.pageNum ?? DEFAULT_PAGE_NUMBER
      });
    }
  }, [serviceDeviceList.order]);

  useEffect(() => {
    if (actionStatus.type === 'assign' && actionStatus.success) {
      handleFetchListServiceDevice({
        deviceTypeSelected: deviceTypeSelected.name as EDeviceType,
        order: serviceDeviceList.order,
        pageNumber: DEFAULT_PAGE_NUMBER,
        callback: onResetActionStatus
      });

      handleFetchListDevicesSummary(currentAccount.id);
    }
  }, [actionStatus]);

  useEffect(() => {
    return () => {
      handleChangeAccount(undefined);
      handleClearForm();
    };
  }, []);

  return {
    refEl,
    form,
    billingForm,
    listAccountType,
    deviceTypeSelected,
    serviceDeviceList,
    deviceReferenceList,
    devicesSummaryList,
    listValidation: listValidation.map((item: TValidation) => ({
      label: item.name,
      value: item.id
    })),
    currentAccount,
    onUpdateAccount: handleUpdateAccount,
    onCancel: handleCancelClick,
    onDeviceTypeChange: handleDeviceTypeChange,
    onServiceDeviceChangeSort: handleServiceDeviceChangeSort,
    onPageNumberChange: handlePageNumberChange
  };
};
