import useResponsive from 'hooks/useResponsive';
import React, { FC, MutableRefObject } from 'react';
import styles from './rightColumn.module.scss';
import { CollapseDivider } from '../CollapseDivider/CollapseDivider';

type Props = {
  toolTipRef: MutableRefObject<
    | {
        onHideTooltip: () => void;
        onShowTooltip: () => void;
      }
    | undefined
  >;
  leftColumnOpen?: boolean;
  children: React.ReactNode;
  className?: string;
  onSideBarClick: () => void;
};

const RightColumn: FC<Props> = ({
  toolTipRef,
  leftColumnOpen,
  className = '',
  children,
  onSideBarClick
}) => {
  const { isDesktop, isSmallDesktop } = useResponsive();

  return (
    <div
      className={`${styles.rightContainer} h-100 d-flex position-relative ${className}`}
      style={{
        marginLeft:
          leftColumnOpen && isSmallDesktop ? '25%' : leftColumnOpen && isDesktop ? '20%' : 0,
        transition: 'all 0.45s ease'
      }}
    >
      <CollapseDivider
        ref={toolTipRef}
        className="position-absolute"
        leftColumnOpen={leftColumnOpen}
        onSideBarClick={onSideBarClick}
      />
      <div
        className="col-12 col-md-9 h-100 w-100 overflow-x-hidden overflow-y-auto custom-scrollbar"
        style={{
          paddingLeft: isDesktop ? (leftColumnOpen ? 10 : 20) : 0,
          paddingRight: isDesktop ? 10 : 0
        }}
      >
        {children}
      </div>
    </div>
  );
};

export { RightColumn };
