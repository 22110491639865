import { useLoaderContext } from 'context/LoaderContext';

import { EHttpStatusCode, Routes } from 'enums';
import { TAccount, TEyeviewUser } from 'models';
import { useEffect, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { EyeviewUserRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';
import { useEOSSContext } from 'context/EOSSContext';
import { ApiErrorResponse } from 'models/ApiError';
import { useTranslation } from 'react-i18next';
import { useAppUtil } from 'context/UtilContext';

export const useCloneEyeviewUserPageController = () => {
  const eyeviewUserRepository = EyeviewUserRepository(AxiosClient);
  const { loader } = useLoaderContext();
  const currentUser = useRouteLoaderData(Routes.EUserRoutes.DETAIL) as TEyeviewUser;
  const [listAccount, setListAccount] = useState<{
    data: TAccount[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });
  const { t } = useTranslation();
  const { openNotification } = useAppUtil();

  const { listValidation } = useEOSSContext();

  // STATE
  const [userData, setUserData] = useState({} as TEyeviewUser);

  // #region remote Data
  const handleGetListAccount = async () => {
    try {
      const { code, data } = await eyeviewUserRepository.getAllValidAccountList();
      if (code === EHttpStatusCode.OK) {
        setListAccount({
          data,
          loading: false
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('accountPage.entity')}`,
        description: message
      });
    }
  };

  const cloneUser = async (params: TEyeviewUser) => {
    loader.start();
    try {
      await eyeviewUserRepository.cloneUser(params);
      return { error: null, success: true };
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'update',
        entity: t('userPage.entity'),
        t
      });
      return { error: message, success: false };
    } finally {
      loader.complete();
    }
  };

  // #endregion

  useEffect(() => {
    if (currentUser) {
      setUserData(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    handleGetListAccount();
  }, []);

  return {
    userData,
    dataAccount: listAccount,
    listValidation,
    cloneUser
  };
};
