export default {
  userPage: {
    breadcrumb: {
      listing: 'Eyeview User Management',
      create: 'Create Eyeview User',
      clone: 'Clone Eyeview User'
    },
    sections: {
      search: {
        title: 'Search Eyeview User',
        instruction: 'Search by username, first name, last name, customer name'
      },
      create: {
        title: 'Create Eyeview User'
      },
      clone: {
        title: 'Clone Eyeview User',
        description: 'Create a new Eyeview User by cloning '
      },
      assignCameraGroup: {
        title: 'Assign Device Group',
        description:
          'Assign the eyeview user <0>{{username}}</0> to the device group from the list below'
      },
      listing: {
        title: 'Eyeview User List',
        emptyDescription: 'By searching eyeview user'
      },
      general: {
        title: 'General'
      },
      permission: {
        title: 'Permission',
        description: 'Assign permissions to the user by choosing from the box below',
        entity: 'Permission'
      },
      cameraGroup: {
        title: 'Device Group',
        entity: 'Device Group'
      }
    },
    entity: 'Eyeview User',
    table: {
      columns: {
        username: 'username',
        accountName: 'customer name',
        valid: 'valid',
        firstName: 'first name',
        lastName: 'last name',
        email: 'email',
        comment: 'comment',
        cameraGroupName: 'Group Name'
      }
    },
    form: {
      permission: {
        title: 'Permission:',
        placeholder: 'Select the permission'
      },
      language: {
        placeholder: 'Select your frontend language'
      }
    },
    confirmModal: {
      addContent: 'Are you sure to submit this User?',
      editContent: 'Are you sure to submit this User?',
      assign: 'Are you sure to assign '
    },
    component: {
      generatePassword: 'Generate Password',
      clonePermission: 'Clone Permission',
      cloneCameraGroup: 'Clone Device Groups'
    },
    '404': {
      title: 'Not Found',
      subTitle: 'Eyeview User with this ID has not existed yet'
    }
  },
  userPermissions: {
    entity: 'Eyeview Permission'
  }
};
