import { TPaginationEOSS, TServerType, TSortOrder } from 'models';
import {
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { ServerRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { Form } from 'antd';
import { TServer } from 'models/Server';
import { useEffect, useState } from 'react';
import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import { EHttpStatusCode, Routes } from 'enums';
import { handleApiError } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { ApiErrorResponse } from 'models/ApiError';
import { useAppUtil } from 'context/UtilContext';

export const useServerListingPageController = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get('search');
  const sortKey = searchParams.get('sortKey');
  const page = searchParams.get('page');
  const order = searchParams.get('order') as TSortOrder;
  const serverRepository = ServerRepository(AxiosClient);
  const { serverId } = useParams<{ serverId: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const currentServerType = useLoaderData() as TServerType;
  const [currentServer, setCurrentServer] = useState<TServer | null>(null);
  const { serverType } = useParams<{ serverType: string }>();
  const { t } = useTranslation();
  const { openNotification } = useAppUtil();

  const { onSetCurrentServerType } = useOutletContext<{
    onSetCurrentServerType: (serverType: TServerType) => void;
  }>();

  const [listServers, setListServers] = useState<{
    data: TServer[];
    isLoading: boolean;
    paging: TPaginationEOSS;
  }>({
    data: [],
    isLoading: false,
    paging: {
      pageLimit: DEFAULT_LIMIT,
      pageNum: Number(page) || DEFAULT_PAGE_NUMBER,
      total: 0,
      totalPage: 0
    }
  });

  function handleResetSearch() {
    searchParams.delete('search');
    searchParams.delete('sortKey');
    searchParams.delete('order');
    searchParams.delete('page');
    setSearchParams(searchParams);
  }

  async function handleFetchList(search?: string) {
    if (listServers.isLoading) return;
    setListServers((prev) => {
      return {
        ...prev,
        isLoading: true
      };
    });

    try {
      const { data, code } = await serverRepository.getListServer({
        search: search,
        serverTypeId: Number(serverType)
      });

      if (code === EHttpStatusCode.OK) {
        setListServers((prev) => {
          return {
            ...prev,
            isLoading: false,
            data: data,
            paging: {
              ...prev.paging,
              total: data.length,
              pageLimit: DEFAULT_LIMIT
            }
          };
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('serverPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.search')} ${t('serverPage.entity')}`,
        description: message
      });
    }
  }

  function handleSearch(searchTextVal: string) {
    if (searchTextVal) {
      searchParams.set('search', searchTextVal);
      searchParams.set('page', '1');
      setSearchParams(searchParams);

      if (searchTextVal === searchText) {
        handleFetchList(searchTextVal);
      }
    }
    navigate({
      pathname: `${Routes.EServerRoutes.INDEX}/${currentServerType.id}`,
      search: searchParams.toString()
    });
  }

  useEffect(() => {
    if (currentServerType && !searchText) {
      form.setFieldValue('searchText', '*'); //Default search is *
      searchParams.set('search', '*');
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [currentServerType]);

  useEffect(() => {
    if (searchText) {
      form.setFieldValue('searchText', searchText);
    }
  }, [searchText]);

  useEffect(() => {
    if (searchText) {
      handleFetchList(searchText === '*' ? '' : searchText || '');
    } else {
      setListServers({
        data: [],
        isLoading: false,
        paging: { pageLimit: DEFAULT_LIMIT, pageNum: DEFAULT_PAGE_NUMBER, total: 0, totalPage: 0 }
      });
    }
  }, [searchText]);

  useEffect(() => {
    if (page) {
      setListServers((prev) => {
        return {
          ...prev,
          paging: {
            ...prev.paging,
            pageNum: Number(page)
          }
        };
      });
    }
  }, [page]);

  useEffect(() => {
    onSetCurrentServerType(currentServerType);
  }, [currentServerType]);

  return {
    currentServer,
    currentServerType,
    listServers,
    serverId,
    sortKey,
    order,
    searchParams,
    searchText,
    page,
    form,
    fetchListServer: handleFetchList,
    setSearchParams,
    handleSearch,
    handleResetSearch,
    onSetCurrentServerType,
    setCurrentServer
  };
};
