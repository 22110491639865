import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TOOLTIP_DELAY } from 'constant';
import { EDeviceStatus, EDeviceType, Routes } from 'enums';
import useResponsive from 'hooks/useResponsive';
import { TOtherDevice } from 'models';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { checkDeviceStatus, splitLastContact } from 'utils/common';
import styles from './tableOtherDevice.module.scss';

type Props = {
  deviceType: EDeviceType.ASTRO_CAMERA | EDeviceType.UPS_MONITOR;
  deviceList: {
    data: TOtherDevice[];
    loading: boolean;
  };
  deviceSelectedRow: React.Key[];
  onDeviceSelectedRowChange: (value: React.Key[]) => void;
};

function TableOtherDeviceBinding({
  deviceType,
  deviceList,
  deviceSelectedRow,
  onDeviceSelectedRowChange
}: Props) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const columnsOtherDevice: ColumnsType<TOtherDevice> = useMemo(
    () => [
      {
        title: t('cameraGroupPage.otherDeviceTable.status').toUpperCase(),
        dataIndex: 'lastContactAt',
        key: 'status',
        render: (value: number) => {
          if (checkDeviceStatus(value)) {
            return (
              <span className="text-nowrap fw-medium" style={{ color: 'var(--sub-5)' }}>
                {EDeviceStatus.ONLINE}
              </span>
            );
          }
          return (
            <span className="fw-medium text-nowrap" style={{ color: 'var(--sub-3)' }}>
              {EDeviceStatus.OFFLINE}
            </span>
          );
        }
      },
      {
        title: t('cameraGroupPage.otherDeviceTable.lastContact').toUpperCase(),
        dataIndex: 'lastContactAt',
        key: 'lastContactAt',
        render: (value: number) => {
          const { date, time } = splitLastContact(value);

          return (
            <div>
              <span>{date}</span>
              <span className="text-nowrap">{time}</span>
            </div>
          );
        }
      },
      {
        title: t('cameraGroupPage.otherDeviceTable.publicIP').toUpperCase(),
        dataIndex: 'publicIp',
        key: 'publicIp',
        render: (value: string) => <span className="text-nowrap">{value}</span>
      },
      {
        title: t('cameraGroupPage.otherDeviceTable.serviceProvider').toUpperCase(),
        dataIndex: 'serviceProvider',
        key: 'serviceProvider',
        render: (value: string) => <span className="text-nowrap">{value}</span>
      }
    ],
    []
  );

  return (
    <Table
      tableLayout="auto"
      dataSource={deviceList.data}
      className={styles.otherDeviceContainer}
      columns={[
        {
          title: t('cameraGroupPage.otherDeviceTable.serialNumber').toUpperCase(),
          dataIndex: 'serialNumber',
          key: 'serialNumber',
          render: (value, record) => {
            return (
              <Tooltip
                trigger={isMobile ? ['click'] : ['hover']}
                destroyTooltipOnHide
                title={value}
                mouseEnterDelay={TOOLTIP_DELAY}
                arrow={false}
                placement="bottom"
              >
                <Link
                  to={`${Routes.EDevicesRoutes.INDEX}/${(record.typeName ?? '').toLowerCase()}/${
                    record.id
                  }?search=${value}`}
                >
                  {value}
                </Link>
              </Tooltip>
            );
          },
          ellipsis: {
            showTitle: false
          }
        },
        ...columnsOtherDevice
      ]}
      rowSelection={{
        columnTitle: t('cameraGroupPage.otherDeviceTable.assigned').toUpperCase(),
        type: 'checkbox',
        hideSelectAll: true,
        selectedRowKeys: deviceSelectedRow,
        onChange: onDeviceSelectedRowChange,
        getCheckboxProps: () => ({
          disabled: true
        })
      }}
      rowKey="serialNumber"
      rowClassName={(_, index) => (index % 2 ? 'row-even' : 'row-odd')}
      scroll={{
        x: 'auto'
      }}
      pagination={false}
      loading={deviceList.loading}
    />
  );
}

export default memo(TableOtherDeviceBinding);
