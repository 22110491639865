import { ArrowBreadcrumbIcon } from 'assets/icons';
import { ERoleKey } from 'enums';
import { usePermission } from 'hooks/usePermission';
import { Link, useMatches, useSearchParams } from 'react-router-dom';
import styles from './breadcrumb.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAppUtil } from 'context/UtilContext';
import { useTranslation } from 'react-i18next';
import { getCopyMessageBasedOnPath } from 'utils/common';

export function Breadcrumb() {
  const { openNotification } = useAppUtil();
  const [searchParams] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const matches: Array<any> = useMatches();
  const crumbs = (matches ?? [])
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  const { allowed } = usePermission(ERoleKey.VIEW);

  const { t } = useTranslation();

  if (!allowed) return null;

  return (
    <div className={styles.container}>
      {crumbs.map((crumb, index) => {
        const isPre = index !== crumbs.length - 1;
        const link = matches[index + 1];

        if (link?.data?.allowCopied) {
          return (
            <div key={index} className={`${styles.crumbItem}`}>
              <CopyToClipboard
                onCopy={() => {
                  // TODO: Double Check Localization Here
                  openNotification({
                    type: 'success',
                    title: getCopyMessageBasedOnPath(link.pathname, t)
                  });
                }}
                text={crumb}
              >
                <span className={styles.allowCopiedText}>{crumb}</span>
              </CopyToClipboard>
            </div>
          );
        }

        return (
          <div key={index} className={`${styles.crumbItem}`}>
            <Link to={`${link.pathname}?${searchParams.toString()}`}>
              <span>{crumb}</span>
            </Link>
            {isPre && <ArrowBreadcrumbIcon />}
          </div>
        );
      })}
    </div>
  );
}
