import { Button, Divider, Form, Input, Select, Tooltip } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { PASSWORD_VALIDATION_REGEX } from 'constant';
import { ERoleKey, EStatusInvalid, EStatusString } from 'enums';
import { usePermission } from 'hooks/usePermission';
import { TAdminUserType, TDomain, TLanguage } from 'models';
import { FormInputTooltip, PasswordCriteriaChecking, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdown } from 'react-icons/io';
import { generatePassword } from 'utils/common';
import { MoreInfoIcon } from 'assets/icons';
import { useWatch } from 'antd/es/form/Form';
import styles from './general.module.scss';
import useResponsive from 'hooks/useResponsive';
import ValidationUtilities from 'utils/validationUtils';

type FieldType = {
  adminType: string;
  domain?: string;
  login: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  street?: string;
  city?: string;
  language: number;
  state?: string;
  zip?: string;
  comment?: string;
  invalid: number;
};

type Props = {
  listLanguage: TLanguage[];
  isDomainAdminType: boolean;
  listAdminTypes?: TAdminUserType[];
  listDomains?: TDomain[];
  editMode?: boolean;
  disableValidField?: boolean;
};

function AdminGeneral({
  listLanguage,
  isDomainAdminType,
  listAdminTypes,
  listDomains,
  editMode,
  disableValidField
}: Props) {
  const form = useFormInstance();
  const { t } = useTranslation();

  const { isMobile } = useResponsive();

  const passwordValue = useWatch('password', form);

  const isAllowCreate = !editMode && usePermission(ERoleKey.CREATE).allowed;

  const isAllowUpdate = editMode && usePermission(ERoleKey.UPDATE).allowed;

  const genPassword = () => {
    const pass = generatePassword();
    form.setFieldsValue({
      password: pass,
      confirmPassword: pass
    });
  };

  const filterOption = (input: string, option?: { label: string; value: string | number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Section
      className={styles.container}
      title={t('adminUserPage.sections.general.title')}
      classNameBody="px-4 pt-3 pb-4"
    >
      {/**
       * ADMINISTRATOR TYPE
       */}
      <FormInputTooltip name="adminType">
        <Form.Item<FieldType> label={t('forms.adminType.label')} name="adminType">
          <Select
            options={
              listAdminTypes?.map((type) => {
                return {
                  label: type.displayName,
                  value: type.name
                };
              }) ?? []
            }
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            disabled={editMode || !isAllowCreate}
            suffixIcon={editMode ? null : <IoMdArrowDropdown color="var(--primary-1)" size={15} />}
          />
        </Form.Item>
      </FormInputTooltip>
      {/**
       * DOMAIN
       */}
      {isDomainAdminType && (
        <FormInputTooltip name="domain">
          <Form.Item<FieldType> label={t('forms.domain.label')} name="domain">
            <Select
              options={
                listDomains?.map((domain) => {
                  return {
                    label: domain.name,
                    value: domain.id
                  };
                }) ?? []
              }
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              suffixIcon={
                editMode ? null : <IoMdArrowDropdown color="var(--primary-1)" size={15} />
              }
              disabled={editMode || !isAllowCreate}
            />
          </Form.Item>
        </FormInputTooltip>
      )}
      {/**
       * VALID
       */}
      <Form.Item<FieldType>
        label={t('forms.valid.label')}
        rules={[{ required: true, message: t('forms.valid.required') }]}
        name="invalid"
      >
        <Select
          disabled={disableValidField}
          optionFilterProp="children"
          filterOption={filterOption}
          options={[
            {
              value: EStatusInvalid.VALID,
              label: EStatusString.VALID
            },
            {
              value: EStatusInvalid.INVALID,
              label: EStatusString.INVALID
            }
          ]}
          suffixIcon={<IoMdArrowDropdown color="var(--primary-1)" size={15} />}
        />
      </Form.Item>
      <Divider className="my-2" />
      {/**
       * LOGIN
       */}
      <FormInputTooltip name="login">
        <Form.Item<FieldType>
          label={t('forms.login.label')}
          rules={[
            { required: true, message: t('forms.login.required') },
            {
              validator: (_, value: string) =>
                ValidationUtilities.loginValidationFn({
                  value,
                  errorMessage: t('forms.login.pattern')
                })
            }
          ]}
          name="login"
        >
          <Input disabled={editMode || !isAllowCreate} />
        </Form.Item>
      </FormInputTooltip>
      <div className="d-flex flex-column flex-sm-row gap-4">
        <div className="flex-fill">
          {/**
           * PASSWORD
           */}
          <Form.Item<FieldType>
            label={t('forms.password.label')}
            rules={[
              { required: !editMode, message: t('forms.password.required') },
              { pattern: PASSWORD_VALIDATION_REGEX, message: '' }
            ]}
            name="password"
            className="flex-fill"
          >
            <Input.Password
              autoComplete="new-password"
              disabled={!isAllowCreate && !isAllowUpdate}
            />
          </Form.Item>
          {/**
           * CONFIRM PASSWORD
           */}
          <Form.Item<FieldType>
            dependencies={['password']}
            label={t('forms.confirmPassword.label')}
            rules={[
              { required: !editMode, message: t('forms.confirmPassword.required') },
              {
                validator: (_, value: string) =>
                  ValidationUtilities.confirmPasswordValidationFn({
                    value,
                    editMode,
                    passwordDependency: passwordValue,
                    errorMessage: t('forms.confirmPassword.notMatching')
                  })
              }
            ]}
            name="confirmPassword"
          >
            <Input.Password disabled={!isAllowCreate && !isAllowUpdate} />
          </Form.Item>

          <Form.Item label={isMobile ? '' : ' '} className="mt-4 mt-sm-0">
            <PasswordCriteriaChecking value={passwordValue} />
          </Form.Item>
        </div>
        {/**
         * GENERATE PASSWORD
         */}
        <Button
          onClick={genPassword}
          className={`${styles.btnPasswordGenerate} btn-outline fw-medium`}
          disabled={!isAllowCreate && !isAllowUpdate}
        >
          {t('components.generatePassword')}
        </Button>
      </div>

      <Divider className={styles.divider} />

      <Form.Item
        className={`${styles.customRequiredStar} mb-0`}
        label={t('forms.name.label')}
        rules={[{ required: true }]}
      >
        <div className="row gx-4">
          {/**
           * FIRST NAME
           */}
          <FormInputTooltip name="firstName" className="col-12 col-slg-6 mb-2 mb-sm-0">
            <Form.Item<FieldType>
              rules={[
                {
                  required: true,
                  message: t('forms.firstName.required')
                },
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.shortNameValidationFn({
                      value,
                      errorMessage: t('forms.firstName.maxLength')
                    })
                }
              ]}
              name="firstName"
            >
              <Input
                addonBefore="First"
                className={styles.addonData}
                disabled={!isAllowCreate && !isAllowUpdate}
              />
            </Form.Item>
          </FormInputTooltip>
          {/**
           * LAST NAME
           */}
          <FormInputTooltip name="lastName" className="col-12 col-slg-6">
            <Form.Item<FieldType>
              rules={[
                {
                  required: true,
                  message: t('forms.lastName.required')
                },
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.shortNameValidationFn({
                      value,
                      errorMessage: t('forms.lastName.maxLength')
                    })
                }
              ]}
              name="lastName"
            >
              <Input
                addonBefore="Last"
                className={styles.addonData}
                disabled={!isAllowCreate && !isAllowUpdate}
              />
            </Form.Item>
          </FormInputTooltip>
        </div>
      </Form.Item>
      {/**
       * EMAIL
       */}
      <FormInputTooltip name="email">
        <Form.Item<FieldType>
          label={t('forms.email.label')}
          rules={[
            {
              required: true,
              message: t('forms.email.required')
            },
            {
              validator: (_, value: string) =>
                ValidationUtilities.emailValidationFn({
                  value,
                  errorMessages: new Map([
                    ['maxLength', t('forms.email.maxLength')],
                    ['pattern', t('forms.email.pattern')]
                  ])
                })
            }
          ]}
          name="email"
        >
          <Input disabled={!isAllowCreate && !isAllowUpdate} />
        </Form.Item>
      </FormInputTooltip>
      {/**
       * PHONE
       */}
      <FormInputTooltip name="phone">
        <Form.Item<FieldType>
          label={t('forms.phone.label')}
          name="phone"
          rules={[
            {
              validator: (_, value: string) =>
                ValidationUtilities.phoneNumberValidationFn({
                  value,
                  errorMessage: t('forms.phone.maxLength')
                })
            }
          ]}
        >
          <Input disabled={!isAllowCreate && !isAllowUpdate} />
        </Form.Item>
      </FormInputTooltip>
      {/**
       * ADDRESS
       */}
      <Form.Item label={t('forms.address.label')} className="mb-0">
        <div className="row gx-4">
          {/**
           * STREET
           */}
          <FormInputTooltip name="street" className="col-12 col-slg-6 mb-2 mb-sm-0">
            <Form.Item<FieldType>
              name="street"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.streetValidationFn({
                      value,
                      errorMessage: t('forms.street.maxLength')
                    })
                }
              ]}
            >
              <Input
                className={styles.addonData}
                addonBefore={t('forms.street.label')}
                disabled={!isAllowCreate && !isAllowUpdate}
              />
            </Form.Item>
          </FormInputTooltip>
          {/**
           * CITY
           */}
          <FormInputTooltip name="city" className="col-12 col-slg-6 mb-2 mb-sm-0">
            <Form.Item<FieldType>
              name="city"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.cityValidationFn({
                      value,
                      errorMessage: t('forms.city.maxLength')
                    })
                }
              ]}
            >
              <Input
                className={styles.addonData}
                addonBefore={t('forms.city.label')}
                disabled={!isAllowCreate && !isAllowUpdate}
              />
            </Form.Item>
          </FormInputTooltip>
          {/**
           * STATE
           */}
          <FormInputTooltip name="state" className="col-12 col-slg-6 mb-2 mb-sm-0">
            <Form.Item<FieldType>
              name="state"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.stateValidationFn({
                      value,
                      errorMessage: t('forms.state.maxLength')
                    })
                }
              ]}
            >
              <Input
                className={styles.addonData}
                addonBefore={t('forms.state.label')}
                disabled={!isAllowCreate && !isAllowUpdate}
              />
            </Form.Item>
          </FormInputTooltip>
          {/**
           * ZIP
           */}
          <FormInputTooltip name="zip" className="col-12 col-slg-6">
            <Form.Item<FieldType>
              name="zip"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.zipValidationFn({
                      value,
                      errorMessage: t('forms.zip.maxLength')
                    })
                }
              ]}
            >
              <Input
                className={styles.addonData}
                addonBefore={t('forms.zip.label')}
                disabled={!isAllowCreate && !isAllowUpdate}
              />
            </Form.Item>
          </FormInputTooltip>
        </div>
      </Form.Item>
      {/**
       * LANGUAGE
       */}
      <Form.Item<FieldType>
        label={
          <div className="d-flex align-items-center">
            <span className="text-truncate">{t('forms.language.label')}</span>
            <Tooltip title={t('forms.language.instruction')} placement="top" trigger="click">
              <div className="d-flex align-items-center cursor-pointer">
                <MoreInfoIcon />
              </div>
            </Tooltip>
          </div>
        }
        name={'language'}
        className="mb-2"
      >
        <Select
          options={listLanguage.map((language) => {
            return {
              label: language.name,
              value: language.id
            };
          })}
          suffixIcon={<IoMdArrowDropdown color="var(--primary-1)" size={15} />}
          disabled={!isAllowCreate && !isAllowUpdate}
        />
      </Form.Item>
      {/**
       * COMMENT
       */}
      <FormInputTooltip name="comment">
        <Form.Item<FieldType>
          label={t('forms.comment.label')}
          name="comment"
          rules={[
            {
              validator: (_, value: string) =>
                ValidationUtilities.commentValidationFn({
                  value,
                  errorMessage: t('forms.comment.maxLength')
                })
            }
          ]}
        >
          <Input.TextArea disabled={!isAllowCreate && !isAllowUpdate} />
        </Form.Item>
      </FormInputTooltip>
    </Section>
  );
}

export default AdminGeneral;
