import { TSortOrder, TServer, TPaginationEOSS } from 'models';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { SortIcon } from 'presentation/components';
import { Link } from 'react-router-dom';

type Props = {
  isLoading: boolean;
  listServers: TServer[];
  sortKey: string | null;
  order: TSortOrder;
  searchText: string | null;
  onChangeSort?: (order?: string | null, columnKey?: string) => void;
  paging: TPaginationEOSS;
};

function TableListVRSServer({
  listServers,
  sortKey,
  order,
  onChangeSort,
  isLoading,
  searchText,
  paging
}: Props) {
  const { t } = useTranslation();

  const columns: ColumnsType<TServer> = useMemo(() => {
    return [
      {
        title: () => t('serverPage.table.columns.serialNumber'),
        dataIndex: 'serialNumber',
        key: 'serialNumber',
        render: (value, record) => {
          //TODO change navigation to Eyeview Session in feature
          return (
            <Link
              to={`${record.serialNumber}${
                searchText ? `?search=${searchText}&page=${paging.pageNum}` : ''
              }`}
            >
              {value}
            </Link>
          );
        }
      },
      {
        title: () => t('serverPage.table.columns.serverName'),
        dataIndex: 'name',
        key: 'name',
        render: (value) => {
          return value;
        }
      },
      {
        title: () => t('serverPage.table.columns.ipAddress'),
        dataIndex: 'ip',
        key: 'ip',
        render: (value) => {
          return value;
        }
      },
      {
        title: () => t('serverPage.table.columns.status'),
        dataIndex: 'status',
        key: 'status',
        sortIcon: SortIcon,
        sortOrder: sortKey === 'status' ? order : null,
        render: (_) => {
          return '-';
        }
      },
      {
        title: () => t('serverPage.table.columns.accountEyeviewUser'),
        dataIndex: 'accountDevices',
        key: 'accountDevices',
        render: (_) => {
          return '-';
        }
      }
    ];
  }, [order, sortKey]);

  return (
    <Table
      loading={isLoading}
      rowKey={'id'}
      dataSource={listServers}
      columns={columns}
      rowClassName={(record, index) => {
        return `${index % 2 ? 'row-even' : 'row-odd'}`;
      }}
      scroll={
        listServers.length > 0
          ? {
              x: 'var(--bs-breakpoint-md)'
            }
          : {}
      }
      pagination={false}
      onChange={(pagination, filters, sorter) => {
        const sorterValue = sorter as SorterResult<TServer>;
        onChangeSort && onChangeSort(sorterValue.order, sorterValue.columnKey?.toString());
      }}
    />
  );
}

export default TableListVRSServer;
