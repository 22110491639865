import { Form } from 'antd';
import { useEOSSContext } from 'context/EOSSContext';
import { useAppUtil } from 'context/UtilContext';
import { EAdminType, EFeatureKey, ERoleKey, EStorageKey, Routes } from 'enums';
import useFlag from 'hooks/useFlag';
import uniq from 'lodash/uniq';
import type {
  TAddressAdminUser,
  TAdminUser,
  TAdminUserType,
  TContactAdminUser,
  TPermission
} from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import { AdministratorRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError, isJsonString, parseLocalUser } from 'utils/common';

type FieldType = {
  adminType: string;
  domain?: string;
  login: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  street?: string;
  city?: string;
  language: number;
  state?: string;
  zip?: string;
  comment?: string;
  invalid: number;
};

export const useAdminDetailPageController = () => {
  const administratorRepository = AdministratorRepository(AxiosClient);
  const [form] = Form.useForm<FieldType>();
  const currentAdminUser = useLoaderData() as TAdminUser;
  const [listPermissionsAdditional, setListPermissionsAdditional] = useState<TPermission[]>([]);
  const [currentAdminType, setCurrentAdminType] = useState<TAdminUserType | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openNotification, openModal } = useAppUtil();
  const isDomainAdminType = currentAdminUser?.adminType?.name === EAdminType.DOMAIN_ADMIN;
  const {
    listPermission,
    listLanguage,
    listAdminType,
    refetchListLanguage,
    refetchListPermission,
    refetchListAdminType
  } = useEOSSContext();
  const [isSubmitting, markAsSubmitting, markAsNotSubmitting] = useFlag(false);
  const [isWeightLower, setIsWeightLower] = useState(false);
  const [canSelfInvalid, setCanSelfInvalid] = useState(false);

  const currentProfile = parseLocalUser(localStorage.getItem(EStorageKey.EOSS_CURRENT_USER));

  const splitByFeature = (permissionKey: string) => {
    const arrFeature = [
      EFeatureKey.ADMIN,
      EFeatureKey.DOMAIN,
      EFeatureKey.ACCOUNT,
      EFeatureKey.DEVICE,
      EFeatureKey.CAMERA_GROUP,
      EFeatureKey.USER,
      EFeatureKey.EYEVIEW_SESSIONS,
      EFeatureKey.SERVER,
      EFeatureKey.SYSTEM
    ];
    const feature = arrFeature.find((f) => permissionKey.includes(f + '_'));
    if (feature) {
      return feature;
    }
  };

  function handleSubmit(values: FieldType) {
    if (isSubmitting) return;
    markAsSubmitting();
    openModal({
      title: t('components.confirmationTitle'),
      content: t('adminUserPage.modal.action', {
        action: t('actions.update').toLowerCase(),
        entity: t('header.admin').toLowerCase()
      }),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      styles: {
        content: {
          width: 'auto'
        }
      },
      width: 'fit-content',
      onOk: async () => {
        const adminTypeId = currentAdminType?.id;
        if (!adminTypeId) return;
        try {
          const addressData = JSON.stringify({
            street: values.street?.trim(),
            city: values.city?.trim(),
            state: values.state?.trim(),
            zip: values.zip?.trim()
          });

          const contactData = JSON.stringify({
            phone: values.phone?.trim(),
            email: values.email.trim()
          });

          const addMorePermissionsView = uniq(
            listPermissionsAdditional
              .filter((permission) => {
                return !permission.name.includes(ERoleKey.VIEW);
              })
              .map((permission) => {
                const permissionView = `${splitByFeature(permission.name)}_${ERoleKey.VIEW}`;
                const findPermission = listPermission.find((p) => p?.name === permissionView);
                return findPermission;
              })
          );

          const addMorePermissionsUpdate = uniq(
            listPermissionsAdditional
              .filter((permission) => {
                return permission.name.includes(ERoleKey.UPDATE);
              })
              .map((permission) => {
                const permissionView = `${splitByFeature(permission.name)}_${ERoleKey.UPDATE}`;
                const findPermission = listPermission.find((p) => p?.name === permissionView);
                return findPermission;
              })
          );

          const adhocPermissions = uniq(
            [...listPermissionsAdditional, ...addMorePermissionsView, ...addMorePermissionsUpdate]
              .filter(
                (permission) =>
                  !currentAdminType.permissions.some(
                    (permissionInAdminType) => permissionInAdminType.id === permission?.id
                  )
              )
              .map((permission) => permission?.id)
          );

          const res = await administratorRepository.updateAdminUser({
            id: currentAdminUser.id,
            adminTypeId: adminTypeId,
            address: isJsonString(addressData) ? addressData : null,
            firstName: values.firstName.trim(),
            languageId: values.language,
            lastName: values.lastName.trim(),
            password:
              values.password && values.password.trim().length > 0 ? values.password : undefined,
            username: values.login,
            comment: values.comment?.trim() || null,
            contact: contactData,
            invalid: values.invalid,
            adhocPermissionIds: adhocPermissions.length > 0 ? adhocPermissions.toString() : '',
            ...(currentAdminUser.domain ? { domainId: currentAdminUser.domain.id } : {})
          });
          if (res) {
            // TODO: Double Check Localization Here
            openNotification({
              type: 'success',
              title: 'Update Admin User',
              description: `${t('components.success')}`
            });
            navigate({ pathname: Routes.EAdminRoutes.LISTING, search: searchParams.toString() });
          }
        } catch (error) {
          const message = handleApiError({
            apiErrorResponse: error as ApiErrorResponse,
            action: 'update',
            entity: t('adminUserPage.entity'),
            t
          });
          openNotification({
            type: 'error',
            title: `${t('actions.update')} ${t('adminUserPage.entity')}`,
            description: message
          });
        } finally {
          markAsNotSubmitting();
        }
      },
      onCancel: () => {
        markAsNotSubmitting();
      }
    });
  }

  useEffect(() => {
    if (listAdminType.length > 0 && currentProfile && currentAdminUser && currentAdminType) {
      const isSysType = Boolean(EAdminType.SYSTEM_ADMIN === currentAdminType.name);
      const myAdminType = listAdminType.find(
        (type) => type?.displayName === currentProfile?.adminTypeDisplayName
      );
      setCanSelfInvalid(currentProfile.username === currentAdminUser.username && !isSysType);

      if (myAdminType && currentAdminType) {
        setIsWeightLower(
          isSysType
            ? myAdminType.weight > currentAdminType.weight
            : myAdminType.weight >= currentAdminType.weight
        );
      }
    }
  }, [listAdminType, currentProfile, currentAdminUser, currentAdminType]);

  useEffect(() => {
    if (currentAdminUser) {
      setListPermissionsAdditional(currentAdminUser.adhocPermissions);
    }
  }, [currentAdminUser]);

  useEffect(() => {
    if (currentAdminUser) {
      const contactData: TContactAdminUser = isJsonString(currentAdminUser.contact)
        ? JSON.parse(currentAdminUser.contact)
        : { phone: undefined, email: undefined };

      const addressData: TAddressAdminUser =
        currentAdminUser.address && isJsonString(currentAdminUser.address)
          ? JSON.parse(currentAdminUser.address)
          : { state: undefined, city: undefined, street: undefined, zip: undefined };
      form.setFieldsValue({
        adminType: currentAdminUser?.adminType?.displayName ?? '',
        login: currentAdminUser.username,
        firstName: currentAdminUser.firstName,
        lastName: currentAdminUser.lastName,
        phone: contactData.phone,
        email: contactData.email,
        domain: currentAdminUser.domain?.name,
        city: addressData.city,
        state: addressData.state,
        street: addressData.street,
        zip: addressData.zip,
        invalid: currentAdminUser.invalid,
        comment: currentAdminUser.comment || ''
      });
    }
  }, [currentAdminUser]);

  useEffect(() => {
    refetchListPermission();
    refetchListLanguage();
    refetchListAdminType();
  }, []);

  useEffect(() => {
    if (listLanguage.length > 0) {
      form.setFieldValue('language', listLanguage[0].id);
    }
  }, [listLanguage]);

  useEffect(() => {
    if (listAdminType.length > 0) {
      const findAdminType = listAdminType.find(
        (type) => currentAdminUser?.adminType?.name === type.name
      );
      if (findAdminType) {
        setCurrentAdminType(findAdminType);
      }
    }
  }, [listAdminType]);

  return {
    listLanguage,
    listPermission,
    currentAdminUser,
    listAdminType,
    canSelfInvalid,
    isWeightLower,
    form,
    isDomainAdminType,
    currentAdminType,
    listPermissionsAdditional,
    setListPermissionsAdditional,
    handleSubmit
  };
};
