import { useEOSSContext } from 'context/EOSSContext';
import type { TAccountType, TDomain, TValidation } from 'models';
import { useEffect, useState } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { AccountRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';

import { EHttpStatusCode, EStatusInvalid, Routes } from 'enums';
import type { TPostAccountsRequest } from 'repositories/Account/AccountRepository';
import { useAccountPageController } from '../AccountPageController';
import { useAppUtil } from 'context/UtilContext';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import { ApiErrorResponse } from 'models/ApiError';
import { orderBy } from 'lodash';

const useCreationAccountController = () => {
  const navigate = useNavigate();
  const { openNotification } = useAppUtil();
  const { t } = useTranslation();

  const { listAccountType, listValidation, refetchListAccountType } = useEOSSContext();
  const { onFetchDomain } = useAccountPageController();
  const accountRepository = AccountRepository(AxiosClient);
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = useForm();
  const [billingForm] = useForm();

  const [domainList, setDomainList] = useState<TDomain[]>([]);

  const isCreatePath = useMatch(Routes.EAccountRoutes.CREATE);

  const handleCreateAccount = async (data: TPostAccountsRequest) => {
    try {
      const { code } = await accountRepository.createAccount(data);
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.create')} ${t('accountPage.entity')}`,
          description: `${t('components.success')}`
        });
        searchParams.set('search', data.name.trim());
        setSearchParams(searchParams);
        navigate({ pathname: Routes.EAccountRoutes.LISTING, search: searchParams.toString() });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'create',
        entity: t('accountPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.create')} ${t('accountPage.entity')}`,
        description: message
      });
    }
  };

  const handleCancelClick = () => navigate(Routes.EAccountRoutes.LISTING, { replace: true });

  useEffect(() => {
    refetchListAccountType();
    onFetchDomain().then(({ success, data }) => {
      if (success) {
        setDomainList(data.filter((domain: TDomain) => domain.invalid === EStatusInvalid.VALID));
      }
    });
  }, []);

  useEffect(() => {
    if (listValidation.length > 0 && isCreatePath) {
      form.setFieldValue('invalidId', listValidation[0].id);
    }
  }, [listValidation]);

  useEffect(() => {
    if (domainList.length > 0 && isCreatePath) {
      form.setFieldValue('domainId', domainList[0].id);
    }
  }, [domainList]);

  useEffect(() => {
    if (listAccountType.length > 0 && isCreatePath) {
      form.setFieldValue('accountTypeId', listAccountType[0].id);
    }
  }, [listAccountType]);

  useEffect(() => {
    form.setFieldValue('contacts', [{ name: '', email: '', phone: '' }]);
  }, []);

  return {
    form,
    billingForm,
    domainList: {
      ...domainList,
      data: domainList.map((item: TDomain) => ({
        label: item.name,
        value: item.id
      }))
    },
    listAccountType: orderBy(listAccountType, 'description', 'asc').map((item: TAccountType) => ({
      label: `${item.description} (${item.name})`,
      value: item.id
    })),
    listValidation: listValidation.map((item: TValidation) => ({
      label: item.name,
      value: item.id
    })),
    onCreate: handleCreateAccount,
    onCancel: handleCancelClick
  };
};

export default useCreationAccountController;
