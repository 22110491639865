export default {
  errors: {
    unknown: 'Something went wrong with your request!',
    notFound: {
      //if the not found message need to be clearer, BE should improve error_code or something else.
      get: 'System could not find {{entity}} {{identifier}}.', // System could not find the customer FPL.!
      delete: 'System could not find {{entity}} {{identifier}}.', // System could not find the customer FPL.!
      update: 'System could not find the resource!',
      create: 'System could not find the resource!',
      other: 'System could not find the resource!' // Bind, assign, unassign, import, export, sync, reboot, scan, run, etc.
    },
    forbidden: {
      get: "You don't have any permission to get the {{entity}}",
      create: "You don't have any permission to create the {{entity}}.",
      update: "You don't have any permission to update the {{entity}}.",
      delete: "You don't have any permission to delete the {{entity}}.",
      other: "You don't have any permission to {{action}} the {{entity}}." // UnBind, bind, assign, unassign, import, export, sync, reboot, scan, run, etc.
    },
    invalidAccountType: 'The device does not belong to an inventory customer!',
    duplicateResource: '{{entity}} {{identifier}} already exists.', //Domain ABC already exists.
    internalServerError:
      'An unexpected error occurred while processing your request. Please try again!',
    badRequest: 'There were errors with your request. Please review and try again!',
    unauthorizedAccess: 'Your account is not authorized to perform this action!',
    expiredToken: 'Your session has expired. Please log in again!',
    notBelongTo: '{{fromIdentifier}} does not belong to {{entity}} {{toIdentifier}}!',
    // Secial case for binding dual sensor cameras
    cameraBindingNotAllowed:
      'Binding separate cameras of a device to a device group is not allowed. Please input the serial number of the device instead!',
    disableResource:
      'The {{entity}} has been disabled. Please contact Eyecast support for assistance!', //The administrator has been disabled. Please contact Eyecast support for assistance.
    userNameOrPasswordIncorrect: 'Invalid username or password. Please try again!',
    custom: {
      deviceGroup: {
        bindUserToDecommissionedGroupError:
          'Cannot bind the eyeview user to a decommissioned group',
        bindCameraToDecommissionedGroupError: 'Cannot bind camera to a decommissioned group'
      }
    },
    passwordInvalid: 'Password does not comply with minimum requirements.'
  }
};
