import { ERoleKey, ESubFeatureKey } from 'enums';
import { TAdminUserType, TPermission } from 'models';
import { PermissionWrapper, Section } from 'presentation/components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PermissionGroup from './PermissionGroup';
import { Form, Select } from 'antd';
import { IoMdArrowDropdown, IoMdCloseCircle } from 'react-icons/io';
import { usePermission } from 'hooks/usePermission';
import styles from './permissionSection.module.scss';

type Props = {
  listPermission: TPermission[];
  isDomainAdminType: boolean;
  currentAdminType: TAdminUserType;
  listPermissionsAdditional: TPermission[];
  onSelectPermission: (permission: { label: string; value: number }) => void;
  onRemovePermission: (permissionId: number) => void;
};

function PermissionSection({
  listPermission,
  currentAdminType,
  isDomainAdminType,
  listPermissionsAdditional,
  onSelectPermission,
  onRemovePermission
}: Props) {
  const { t } = useTranslation();
  const isAllowDelete = usePermission(ERoleKey.UPDATE, ESubFeatureKey.PERMISSION).allowed;
  const isShowAdhocPermission = false; //TODO Temp Remove adhoc permissions UI for all admin types for EP1

  return (
    <PermissionWrapper.Update
      subFeature={ESubFeatureKey.PERMISSION}
      config={{ displayType: 'disabled' }}
    >
      <Section
        title={t('adminUserPage.sections.permission.title')}
        classNameBody="px-4 pt-3 pb-4"
        allowCollapse
        expandedByDefault
        keyPanel="admin-page-permission-section"
      >
        <div className={styles.container}>
          <h5 className="mb-4">
            {t('adminUserPage.sections.permission.description')}{' '}
            <span className="fw-medium">{currentAdminType.displayName}</span>.
          </h5>
          <div className="col-12">
            <PermissionGroup
              permissionName={currentAdminType.displayName}
              listPermissions={currentAdminType.permissions}
            />
            {!isDomainAdminType && isShowAdhocPermission && (
              <>
                <h5 className="mb-4 user-select-none" style={{ color: 'var(--grey-1)' }}>
                  <Trans i18nKey="adminUserPage.sections.permission.instruction">
                    <br />
                  </Trans>
                </h5>
                <Form.Item label={t('forms.permission.label')}>
                  <Select
                    value={null}
                    placeholder={t('forms.permission.placeholder')}
                    options={listPermission
                      .filter(
                        (permission) =>
                          !currentAdminType.permissions.some(
                            (permissionInAdminType) => permissionInAdminType.id === permission.id
                          ) &&
                          !listPermissionsAdditional.some(
                            (permissionAdditional) => permissionAdditional.id === permission.id
                          )
                      )
                      .map((permission) => {
                        return {
                          label: permission.displayName,
                          value: permission.id
                        };
                      })}
                    onChange={(value, option) => {
                      const data = option as { label: string; value: number };
                      onSelectPermission(data);
                    }}
                    suffixIcon={<IoMdArrowDropdown color="var(--primary-1)" size={15} />}
                  />
                  {listPermissionsAdditional.length > 0 && (
                    <div className="mt-3 d-flex gap-2 flex-wrap me-4">
                      {listPermissionsAdditional.map((permission) => {
                        return (
                          <div
                            key={permission.id}
                            className="px-4 py-2 rounded-5 d-flex align-items-center"
                            style={{ backgroundColor: 'var(--primary-5)' }}
                          >
                            <div className="me-2 fs-13">{permission.displayName}</div>
                            <div
                              className={isAllowDelete ? 'cursor-pointer' : ''}
                              onClick={() => isAllowDelete && onRemovePermission(permission.id)}
                            >
                              <IoMdCloseCircle color="var(--grey-1)" size={16} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Form.Item>
              </>
            )}
          </div>
        </div>
      </Section>
    </PermissionWrapper.Update>
  );
}

export default PermissionSection;
