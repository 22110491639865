import { EHttpStatusCode, EStorageKey, EThermalCameraType, Routes } from 'enums';
import type {
  DataList,
  TAccount,
  TCameraBinding,
  TCameraGroup,
  TEyeviewUser,
  TEyeviewUserAssign
} from 'models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { CameraGroupRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import {
  convertToPascalCase,
  handleApiError,
  isApiErrorResponse,
  replaceAllSpace
} from 'utils/common';
import { useLoaderContext } from 'context/LoaderContext';
import { useAppUtil } from 'context/UtilContext';
import type {
  TAssignEyeViewUser,
  TBindCamera,
  TCameraGroupSearch,
  TSyncCameraConfig
} from './cameraGroupPage.types';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_NUMBER } from 'constant';
import { orderBy } from 'lodash';
import {
  ApiErrorResponse,
  cameraBindingNotAllowed,
  invalidTypeError,
  unAuthorizedAccessError
} from 'models/ApiError';
import { SegmentedValue } from 'antd/es/segmented';

export const useCameraGroupsPageController = () => {
  const cameraGroupRepository = CameraGroupRepository(AxiosClient);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loader } = useLoaderContext();
  const { openNotification } = useAppUtil();

  const currCameraGroup = useRouteLoaderData(Routes.ECameraGroupRoutes.DETAILED) as TCameraGroup;
  const { cameraGroupId } = useParams<{ cameraGroupId: string }>();

  const [cameraBindingSwitchValue, setCameraBindingSwitchValue] = useState<string>(
    convertToPascalCase(EThermalCameraType.OPTICAL.toString())
  );
  const [displaySyncToggle, setDisplaySyncToggle] = useState<boolean>(false);
  const [accountList, setAccountList] = useState<DataList<Array<TAccount>>>({
    data: [],
    loading: false
  });
  const [currentCameraGroup, setCurrentCameraGroup] = useState<TCameraGroup>();
  const [eyeviewUserList, setEyeviewUserList] = useState<DataList<Array<TEyeviewUser>>>({
    data: [],
    loading: false
  });

  const [searchForm] = useForm<TCameraGroupSearch>();
  const [bindCameraForm] = useForm<TBindCamera>();
  const [syncCameraForm] = useForm<TSyncCameraConfig>();
  const [assignEyeviewUserForm] = useForm<TAssignEyeViewUser>();

  const [searchParams, setSearchParams] = useSearchParams();

  const account = searchParams.get('accountId');
  const searchKey = searchParams.get('search');

  const [actionStatus, setActionStatus] = useState<{
    type: 'sync' | 'bind' | 'assign' | null;
    success: boolean;
  }>({
    type: null,
    success: false
  });

  const deviceBindingExtraOptions = useMemo(
    () => [
      convertToPascalCase(EThermalCameraType.OPTICAL),
      convertToPascalCase(EThermalCameraType.THERMAL)
    ],
    []
  );

  const refetchCountRef = useRef<number>(0);

  const resetBindForm = () => {
    bindCameraForm.resetFields();
  };

  const handleSearchChange = useCallback((value: string) => {
    if (value === '') {
      searchParams.delete('search');
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
  }, []);

  const handleCreateCameraGroupClick = useCallback(
    () => navigate(Routes.ECameraGroupRoutes.CREATE),
    []
  );

  const handleSearchSubmit = useCallback(
    (values: TCameraGroupSearch) => {
      if (values.groupName) {
        const params = new URLSearchParams();
        Number(values.accountId) !== -1 &&
          values.accountId &&
          params.set('accountId', values.accountId.toString());
        values.groupName && params.set('search', values.groupName);
        params.set('page', `${DEFAULT_PAGE_NUMBER}`);

        if (
          values.groupName === searchParams.get('search') &&
          values.accountId.toString() === (searchParams.get('accountId') ?? '-1')
        ) {
          refetchCountRef.current += 1;
        } else {
          refetchCountRef.current = 0;
        }
        navigate({
          pathname: Routes.ECameraGroupRoutes.LISTING,
          search: params.toString()
        });
      } else {
        navigate({
          pathname: Routes.ECameraGroupRoutes.LISTING
        });
      }
    },
    [refetchCountRef, navigate, searchParams]
  );

  const handleFetchListAccount = async () => {
    try {
      setAccountList((prev) => ({
        ...prev,
        loading: true
      }));
      const { code, data } = await cameraGroupRepository.getAllAccounts();
      if (code == EHttpStatusCode.OK) {
        setAccountList({
          data,
          loading: false
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('accountPage.entity')}`,
        description: message
      });
    } finally {
      setAccountList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleFetchListEyeviewUserByAccount = async (accountName: string) => {
    try {
      loader.start();
      setEyeviewUserList((prev) => ({
        ...prev,
        loading: true
      }));
      const { code, data } = await cameraGroupRepository.getListEyeviewUserByAccountName({
        accountName
      });
      if (code == EHttpStatusCode.OK) {
        setEyeviewUserList({
          data: orderBy(data, 'username', 'asc'),
          loading: false
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        identifier: accountName,
        entity: t('userPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('userPage.entity')}`,
        description: message
      });
    } finally {
      loader.complete();
      setEyeviewUserList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleDeleteCameraGroup = async (cameraGroupId: number, callback: () => void) => {
    try {
      const code = await cameraGroupRepository.deleteCameraGroup(cameraGroupId);
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.delete')} ${t('cameraGroupPage.entity')}`,
          description: `${t('components.success')}`
        });

        const search = new URLSearchParams({
          search: '*', //03/22/2024: Meeting Josh: keep search * on group delete
          page: `${DEFAULT_PAGE_NUMBER}`
        });

        navigate(`${Routes.ECameraGroupRoutes.LISTING}?${search.toString()}`);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'delete',
        entity: t('cameraGroupPage.entity'),
        identifier: currCameraGroup.name,
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.delete')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
    } finally {
      callback();
    }
  };

  const handleBindCameraSubmit = async ({
    serialNumber,
    cameraGroupId,
    callback
  }: {
    serialNumber: string;
    cameraGroupId?: string;
    callback: () => void;
  }) => {
    try {
      if (!cameraGroupId) return;
      const cameraGroupIdNumber = Number(cameraGroupId);
      const code = await cameraGroupRepository.bindCameraToCameraGroup({
        cameraGroupId: cameraGroupIdNumber,
        serialNumbers: replaceAllSpace(serialNumber)
      });
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.bind')} ${t('camera.entity')}`,
          description: `${t('components.success')}`,
          onClose: () => {
            sessionStorage.removeItem(EStorageKey.CAMERA_GROUP_BINDING_KEYS);

            const elements = document.querySelectorAll(
              '#table-device-group-binding-device-group-page tbody tr.new-group'
            );
            if (elements.length > 0) {
              elements.forEach((element) => {
                element.classList.remove('new-group');
              });
            }
          }
        });
        sessionStorage.setItem(
          EStorageKey.CAMERA_GROUP_BINDING_KEYS,
          JSON.stringify([replaceAllSpace(serialNumber)])
        );
        setActionStatus({
          type: 'bind',
          success: true
        });
        bindCameraForm.resetFields();
      }
    } catch (error) {
      // Special case for bind camera has different account with Device Group
      if (isApiErrorResponse(error)) {
        const { error: responseError } = error as ApiErrorResponse;
        if (responseError === unAuthorizedAccessError.error) {
          openNotification({
            type: 'error',
            title: `${t('actions.bind')} ${t('camera.entity')}`,
            description: t('errors.notBelongTo', {
              fromIdentifier: serialNumber,
              entity: t('accountPage.entity').toLowerCase(),
              toIdentifier: currentCameraGroup?.accountName
            })
          });
          return;
        }

        // Special case for binding dual sensor camera not allowed
        if (responseError === cameraBindingNotAllowed.error) {
          openNotification({
            type: 'error',
            title: `${t('actions.bind')} ${t('camera.entity')}`,
            description: t('errors.cameraBindingNotAllowed')
          });
          return;
        }

        if (responseError === invalidTypeError.error) {
          // TODO Because INVALID_TYPE used for both
          // Device type has 0 camera
          // Cannot bind camera to a decommissioned group
          // So temp fix in FE get BE message to show the error
          openNotification({
            type: 'error',
            title: `${t('actions.bind')} ${t('camera.entity')}`,
            description: error.message
          });
          return;
        }
      }

      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        actionStr: t('actions.bind'),
        entity: t('cameraGroupPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.bind')} ${t('camera.entity')}`,
        description: message
      });
    } finally {
      callback();
    }
  };

  const handleSyncCameraSubmit = async (cameraId: string, callback: () => void) => {
    try {
      const cameraGroupIdNumber = Number(cameraGroupId);
      if (!cameraGroupId || Number.isNaN(cameraGroupIdNumber)) {
        openNotification({
          type: 'error',
          title: `${t('actions.sync')} ${t('cameraConfig.entity')}`,
          description: t('cameraGroupPage.errorMessage.cameraGroupId')
        });
      }
      const code = await cameraGroupRepository.syncCameraConfig({
        cameraGroupId: cameraGroupIdNumber,
        cameraId: Number(cameraId)
      });
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.sync')} ${t('cameraConfig.entity')}`,
          description: `${t('components.success')}`
        });
        setActionStatus({
          type: 'sync',
          success: true
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        actionStr: t('actions.sync'),
        entity: t('cameraConfig.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.sync')} ${t('cameraConfig.entity')}`,
        description: message
      });
    } finally {
      callback();
    }
  };

  const handleCameraBindingSwitchChange = useCallback((value: SegmentedValue) => {
    setCameraBindingSwitchValue(`${value}`);
  }, []);

  const handleAssignEyeviewUser = async (eyeviewUserId: number, callback: () => void) => {
    if (!eyeviewUserId || !currentCameraGroup?.id) return;
    try {
      const code = await cameraGroupRepository.assignEyeviewUserToCameraGroup({
        cameraGroupId: currentCameraGroup.id,
        userId: eyeviewUserId
      });
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.assign')} ${t('userPage.entity')}`,
          description: `${t('components.success')}`,
          onClose: () => {
            sessionStorage.removeItem(EStorageKey.EYEVIEW_USER_ASSIGNMENT_KEYS);
            const element = document.querySelector(
              '#table-eyeview-user-assignment-list-device-group-detail tbody tr.new-group'
            );
            if (element) {
              element.classList.remove('new-group');
            }
          }
        });
        sessionStorage.setItem(
          EStorageKey.EYEVIEW_USER_ASSIGNMENT_KEYS,
          JSON.stringify([eyeviewUserId])
        );
        setActionStatus({
          type: 'assign',
          success: true
        });
      }
    } catch (error) {
      // Special case for bind user to a decommissioned group
      if (isApiErrorResponse(error)) {
        const { error: responseError } = error as ApiErrorResponse;

        if (responseError === invalidTypeError.error) {
          openNotification({
            type: 'error',
            title: `${t('actions.assign')} ${t('userPage.entity')}`,
            description: t('errors.custom.deviceGroup.bindUserToDecommissionedGroupError')
          });
          return;
        }
      }

      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        actionStr: t('actions.assign'),
        action: 'other',
        entity: t('userPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.assign')} ${t('userPage.entity')}`,
        description: message
      });
    } finally {
      callback();
    }
  };

  const handleResetActionStatus = () =>
    setActionStatus({
      type: null,
      success: false
    });

  useEffect(() => {
    handleFetchListAccount();
  }, []);

  useEffect(() => {
    searchForm.setFieldValue('groupName', searchKey);
  }, [searchKey]);

  useEffect(() => {
    if (accountList.data.length > 0) {
      searchForm.setFieldValue('accountId', account ? Number(account) : -1);
    }
  }, [accountList, account]);

  useEffect(() => {
    if (currentCameraGroup && currentCameraGroup?.cameras.length > 0) {
      const isAllOptical =
        currentCameraGroup.cameras.filter(
          (value: TCameraBinding) =>
            (value.cameraType ?? '').toLowerCase() === EThermalCameraType.THERMAL.toLowerCase()
        ).length === 0;
      const isAllThermal =
        currentCameraGroup.cameras.filter(
          (value: TCameraBinding) =>
            (value.cameraType ?? '').toLowerCase() === EThermalCameraType.OPTICAL.toLowerCase()
        ).length === 0;
      if (isAllThermal) {
        setCameraBindingSwitchValue(convertToPascalCase(EThermalCameraType.THERMAL.toString()));
      } else if (isAllOptical) {
        setCameraBindingSwitchValue(convertToPascalCase(EThermalCameraType.OPTICAL.toString()));
      }

      setDisplaySyncToggle(!isAllOptical && !isAllThermal);
    } else {
      setDisplaySyncToggle(false);
      // Reset Toggle to Optical when cameras binding empty (length = 0)
      setCameraBindingSwitchValue(convertToPascalCase(EThermalCameraType.OPTICAL.toString()));
    }
  }, [currentCameraGroup]);

  useEffect(() => {
    if (currCameraGroup) {
      setCurrentCameraGroup(currCameraGroup);
      handleFetchListEyeviewUserByAccount(currCameraGroup.accountName);
    }
  }, [currCameraGroup]);

  const assignedEyeviewUserList = useMemo(
    () => currentCameraGroup?.users.map((user: TEyeviewUserAssign) => user.id),
    [currentCameraGroup]
  );

  useEffect(() => {
    if (eyeviewUserList) {
      const eyeviewData = eyeviewUserList.data.filter(
        (data) => !(assignedEyeviewUserList ?? []).includes(data.id)
      );
      assignEyeviewUserForm.setFieldValue(
        'eyeviewUserId',
        eyeviewData.length > 0 ? eyeviewData[0].id : null
      );
    }
  }, [eyeviewUserList, assignedEyeviewUserList]);

  useEffect(() => {
    if (currentCameraGroup && currentCameraGroup.cameras.length > 0) {
      const cameraFilter = currentCameraGroup.cameras.filter(
        (camera: TCameraBinding) =>
          (camera.cameraType ?? '').toLowerCase() === (cameraBindingSwitchValue ?? '').toLowerCase()
      )[0]?.id;
      if (cameraFilter) {
        syncCameraForm.setFieldValue('cameraId', cameraFilter);
      }
    }
  }, [cameraBindingSwitchValue, currentCameraGroup]);

  return {
    displaySyncToggle,
    refetchCountRef,
    searchForm,
    bindCameraForm,
    syncCameraForm,
    assignEyeviewUserForm,
    cameraGroupId,
    assignedEyeviewUserList,
    accountList,
    currentCameraGroup,
    eyeviewUserList,
    actionStatus,
    deviceBindingExtraOptions,
    cameraBindingSwitchValue,
    onDeleteCameraGroup: handleDeleteCameraGroup,
    onSearchChange: handleSearchChange,
    onCreateCameraGroupClick: handleCreateCameraGroupClick,
    onSearchSubmit: handleSearchSubmit,
    onSyncCameraFormSubmit: handleSyncCameraSubmit,
    onBindCameraFormSubmit: handleBindCameraSubmit,
    onAssignEyeviewUser: handleAssignEyeviewUser,
    onChangeCurrentCamera: (value: TCameraGroup) => setCurrentCameraGroup(value),
    onResetActionStatus: handleResetActionStatus,
    onCameraBindingSwitchChange: handleCameraBindingSwitchChange,
    resetBindForm
  };
};
