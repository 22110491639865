import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { EStatusInvalid, EStatusString } from 'enums';
import { TDomain, TPaginationEOSS, TSortOrder } from 'models';
import { SortIcon } from 'presentation/components/SortIcon/SortIcon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './tableListDomain.module.scss';
import useResponsive from 'hooks/useResponsive';
import { TOOLTIP_DELAY } from 'constant';
import { SmartTooltip } from 'presentation/components';

type Props = {
  isLoading: boolean;
  listDomains: TDomain[];
  sortKey: string | null;
  order: TSortOrder;
  searchText: string | null;
  onChangeSort?: (order?: string | null, columnKey?: string) => void;
  paging: TPaginationEOSS;
};

function TableListDomain({
  listDomains,
  sortKey,
  order,
  searchText,
  onChangeSort,
  isLoading,
  paging
}: Props) {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();

  const columns: ColumnsType<TDomain> = useMemo(() => {
    return [
      {
        title: () => t('domainPage.table.columns.name'),
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => {
          return (
            <Link
              data-name="name"
              to={`${record.id}${searchText ? `?search=${searchText}&page=${paging.pageNum}` : ''}`}
            >
              {value}
            </Link>
          );
        }
      },
      {
        title: () => t('domainPage.table.columns.valid'),
        dataIndex: 'invalid',
        key: 'invalid',
        sortIcon: SortIcon,
        sortOrder: sortKey === 'invalid' ? order : null,
        sorter: (a, b) => a.invalid - b.invalid,
        render: (value) => (
          <span
            data-name="invalid"
            className={`${Number(value) === EStatusInvalid.VALID ? styles.success : styles.error}`}
          >
            {Number(value) === EStatusInvalid.VALID ? EStatusString.VALID : EStatusString.INVALID}
          </span>
        )
      },
      {
        title: () => t('domainPage.table.columns.comment'),
        dataIndex: 'comment',
        key: 'comment',
        render: (value) => {
          return (
            <SmartTooltip
              delayTime={TOOLTIP_DELAY}
              TypographyComponentProps={{
                style: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }}
              TooltipComponentProps={{
                trigger: 'hover'
              }}
            >
              <span data-name="comment">{value}</span>
            </SmartTooltip>
          );
        }
      }
    ];
  }, [order, sortKey, searchText, paging]);
  return (
    <Table
      data-testid="tableListDomain"
      className={styles.container}
      loading={isLoading}
      rowKey={'id'}
      tableLayout="fixed"
      dataSource={listDomains}
      columns={columns}
      rowClassName={(record, index) => {
        return `${index % 2 ? 'row-even' : 'row-odd'}`;
      }}
      scroll={
        listDomains.length > 0
          ? {
              x: 'auto',
              y: `calc(var(--app-height) - ${isDesktop ? '261px' : '251px'})` // 237px | 227px: not magic number (header 57px + breadcrumb 28px + padding 16px + header section 30px + header table 40px + pagination 44px + 30px (more space))
            }
          : {
              x: 'min-content'
            }
      }
      pagination={{
        pageSize: paging.pageLimit,
        current: paging.pageNum,
        total: paging.total
      }}
      onChange={(pagination, filters, sorter) => {
        const sorterValue = sorter as SorterResult<TDomain>;
        onChangeSort && onChangeSort(sorterValue.order, sorterValue.columnKey?.toString());
      }}
    />
  );
}

export default TableListDomain;
