import { Form } from 'antd';
import { useLoaderData, useOutletContext } from 'react-router-dom';
import { TServer, TServerType } from 'models';
import { useEffect } from 'react';

export type ServerFieldType = {
  type: string;
  serialNumber: number;
  name: string;
  ip: string;
  url: string;
  usage: string;
  comment: string | null;
};

export const useServerDetailPageController = () => {
  const [formGeneral] = Form.useForm();
  const currentServer = useLoaderData() as TServer;
  const { currentServerType } = useOutletContext<{
    currentServerType: TServerType;
  }>();

  useEffect(() => {
    if (currentServer) {
      formGeneral.setFieldsValue({
        type: currentServerType.displayName,
        serialNumber: currentServer.serialNumber,
        name: currentServer.name,
        ip: currentServer.ip,
        url: currentServer.url,
        usage: currentServer.usage,
        comment: currentServer.comment
      });
    }
  }, [currentServer]);

  return {
    currentServer,
    formGeneral
  };
};
