import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EStorageKey, Routes } from 'enums';
import { TCameraGroupAssign } from 'models';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './tableDeviceGroupAssignment.module.scss';

type Props = {
  listCameraGroupAssignment: {
    data: TCameraGroupAssign[];
    loading: boolean;
  };
  listCameraGroupRowSelected: React.Key[];
  allowedAssignEyeviewUserToCameraGroup: boolean;
  onListCameraGroupRowSelectedChange: (value: React.Key[]) => void;
};

const TableDeviceGroupAssignment: FC<Props> = ({
  listCameraGroupAssignment,
  listCameraGroupRowSelected,
  allowedAssignEyeviewUserToCameraGroup,
  onListCameraGroupRowSelectedChange
}) => {
  const { t } = useTranslation();

  const columnsCameraGroupAssignment: ColumnsType<TCameraGroupAssign> = useMemo(
    () => [
      {
        title: t('userPage.table.columns.cameraGroupName').toUpperCase(),
        key: 'name',
        dataIndex: 'name',
        render: (value, record) => (
          <Link
            className="text-nowrap"
            to={`${Routes.ECameraGroupRoutes.LISTING}/${record.id}?search=${value}`}
          >
            {value}
          </Link>
        )
      }
    ],
    []
  );

  function checkGroupIsNew(id: number): boolean {
    const getItem = sessionStorage.getItem(EStorageKey.USER_GROUPS_BINDING_KEYS);
    const listKeys: Array<number> = getItem ? JSON.parse(getItem) : [];
    if (listKeys.length > 0) {
      return Boolean(listKeys.find((key) => key === id));
    }
    return false;
  }

  return (
    <Table
      id="table-device-group-assignment-eyeview-user-page"
      className={styles.container}
      tableLayout="auto"
      dataSource={listCameraGroupAssignment.data}
      loading={listCameraGroupAssignment.loading}
      columns={columnsCameraGroupAssignment}
      rowClassName={({ id }, index) =>
        `${index % 2 ? 'row-even' : 'row-odd'} ${checkGroupIsNew(id) ? 'new-group' : ''}`
      }
      rowKey={(record) => record.id}
      scroll={{
        x: listCameraGroupAssignment.data.length > 0 ? 'auto' : 'min-content'
      }}
      pagination={false}
      rowSelection={{
        type: 'checkbox',
        hideSelectAll: true,
        columnTitle: t('components.assigned').toUpperCase(),
        columnWidth: 100,
        selectedRowKeys: listCameraGroupRowSelected,
        onChange: onListCameraGroupRowSelectedChange,
        getCheckboxProps: () => ({
          disabled: !allowedAssignEyeviewUserToCameraGroup
        })
      }}
    />
  );
};

export default TableDeviceGroupAssignment;
