import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './loggingSection.module.scss';
import { TLoggingOption } from './eyeviewSessionPage.types';

type Props = {
  disable: boolean;
  isLoading: boolean;
  listOptions: TLoggingOption[];
  onSubmit: (newListOptions: TLoggingOption[]) => void;
  isUpdatingLogging: boolean;
  onLoggingOptionSelected: (currentListOptions: TLoggingOption[]) => void;
};

function LoggingSection({
  isLoading,
  listOptions,
  isUpdatingLogging,
  disable,
  onSubmit,
  onLoggingOptionSelected
}: Props) {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<React.Key[]>(
    listOptions.filter((val) => val.value).map((val) => val.option)
  );
  const columns: ColumnsType<TLoggingOption> = useMemo(() => {
    return [
      {
        title: () => t('eyeviewSessionPage.table.columns.option'),
        dataIndex: 'option',
        key: 'option'
      }
    ];
  }, []);

  // Update selectedRow when listOptions is changed (i.e when user clicks on reload button).
  useEffect(() => {
    setSelectedRow(listOptions.filter((val) => val.value).map((val) => val.option));
  }, [listOptions]);

  function handleOnSubmit() {
    const res = listOptions.map((val) => {
      return {
        ...val,
        value: selectedRow.includes(val.option)
      };
    });

    onSubmit(res);
  }

  return (
    <>
      <Table
        rowSelection={{
          columnTitle: t('eyeviewSessionPage.table.columns.enable'),
          type: 'checkbox',
          hideSelectAll: true,
          columnWidth: 100,
          selectedRowKeys: selectedRow,
          onChange: (selectedRowKeys) => {
            setSelectedRow(selectedRowKeys);
            // Throw the selectedRowKeys to the parent component in order to check if the user has changed any option.
            const currentListOptions = listOptions.map((val) => {
              return {
                ...val,
                value: selectedRowKeys.includes(val.option)
              };
            });
            onLoggingOptionSelected(currentListOptions);
          },
          getCheckboxProps: () => ({
            disabled: disable
          })
        }}
        className={styles.ctnTable}
        tableLayout="auto"
        loading={isLoading}
        rowKey="option"
        dataSource={listOptions}
        columns={columns}
        scroll={
          listOptions.length > 0
            ? {
                x: 'auto'
              }
            : {}
        }
        pagination={false}
      />
      <div className="me-3" style={{ background: 'var(--sub-1)' }}>
        <div className="text-end pt-2 pb-4">
          <Button
            className={'btn-fill fw-semibold'}
            loading={isUpdatingLogging}
            onClick={handleOnSubmit}
            disabled={isLoading}
          >
            {t('components.submit')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default LoggingSection;
