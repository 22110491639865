export default {
  devicePage: {
    breadcrumb: {
      listing: 'Device Management',
      copied: 'Serial number copied!'
    },
    cameraOnline: 'Camera is online, but not recording',
    cameraRecording: 'Camera is recording',
    entity: 'Device',
    cameraEntity: 'Camera',
    sections: {
      search: {
        title: 'Search Device',
        instruction: 'Search {{deviceTypeName}} device by serial number or customer'
      },
      import: {
        title: 'Import Device',
        instruction: 'Import device by excel file',
        buttonText: 'Import'
      },
      export: {
        title: 'Export Device',
        instruction: 'Export device selected',
        buttonText: 'Export'
      },
      unassign: {
        title: 'Unassign Service Device',
        instruction:
          'Reset service device <0>{{serialNumber}}</0> configuration and unassign it from <1>{{accountName}}</1> to a customer that is of type "Unassigned Device" (SU) as below: ',
        buttonText: 'Unassign'
      },
      reboot: {
        title: 'Reboot Service Device',
        instruction: 'Reboot service device <0>{{serialNumber}}</0> remotely',
        buttonText: 'Reboot'
      },
      bindCameraGroup: {
        title: 'Bind Device Group',
        instruction:
          'Bind service device <0>{{serialNumber}}</0> to a device group from the list below',
        buttonText: 'Bind',
        entity: 'Device Group'
      },
      scanNetwork: {
        title: 'Scan Network',
        instruction: 'Scan current network',
        buttonText: 'Scan'
      },
      speedTest: {
        title: 'Speed Test',
        instruction: 'Run speed test',
        buttonText: 'Run'
      },
      listing: {
        title: '{{deviceTypeName}} List'
      },
      deviceStatus: {
        title: '{{deviceType}} Service Device ({{serialNumber}})'
      },
      otherDeviceStatus: {
        title: '{{deviceType}} ({{serialNumber}})'
      },
      deviceInfo: {
        title: 'Device Information',
        type: 'Type:',
        model: 'Model:',
        softwareVersion: 'Software Version:',
        'SOC&POE': 'SOC (RPi) Board and POE:',
        serialNumber: 'Serial Number:',
        mac: 'MAC Address:',
        localIP: 'Local IP Address:',
        ipAddress: 'IP Address:',
        internetServiceProvider: 'Internet Service Provider:',
        service: {
          ipService: 'Service:',
          thermalService: 'Thermal Video Service:',
          opticalService: 'Optical Video Service:',
          thermalAnalyticService: 'Thermography Analytics Service:',
          shortThermalAnalyticService: 'Thermog Analytics Service:'
        },
        brvServer: 'BVR Server:',
        usageStartDateAndTime: 'Usage Start Date and Time:',
        shortUsageStartDateAndTime: 'Usage Start Date & Time:',
        cameraName: 'Camera Name:',
        cameraLens: 'Camera Lens:',
        customer: 'Customer:',
        ram: 'DRAM:',
        tooltip: {
          ipAddress: 'Local IP Address / Public IP Address / Internet Service Provider',
          model: 'Module_Product Number_Firmware',
          modelAstro: 'SOC',
          SOC: 'SOC/DRAM',
          POE: 'POE'
        },
        cpuTemp: 'CPU Temperature:',
        barcStatus: 'BARC Status:',
        AIModelNameVersion: 'AI Model Name Version:',
        attachedCamera: 'Attached Camera:',
        lineVoltage: 'Line Voltage:',
        batteryVoltage: 'Battery Voltage:',
        load: 'Load:',
        batteryCharge: 'Battery Charge:',
        batteryDate: 'Battery Date:'
      },
      deviceSystemRuntime: {
        title: 'Device System Runtime Stats',
        cpuUtil: 'CPU Utilization:',
        dramUtil: 'DRAM Utilization:',
        sdCardUtil: 'SD card Utilization:',
        cpuTemp: 'CPU Temperature:',
        kernel: 'Kernel:'
      },
      deviceService: {
        title: 'Device Service',
        warningMessageInvalid: 'The customer this device is assigned to is invalid.',
        warningMessageSUAccount:
          'The customer this device is assigned to is of Unassigned Devices (SU).',
        account: 'Customer',
        form: 'Form',
        brvServerPrimary: 'Primary BVR Server',
        bvrServerSecondary: 'Secondary BVR Server',
        bvrServerTertiary: 'Tertiary BVR Server',
        location: 'Location',
        comment: 'comment',
        serviceStartTime: 'Service Start Time'
      },
      camera: {
        title: 'Camera'
      },
      cameraGroupBinding: {
        title: 'Device Group Binding'
      },
      barcStats: {
        title: 'BARC Stats',
        status: 'Status:'
      },
      jetsonStats: {
        title: 'JETSON Stats'
      },
      seekStats: {
        title: 'SEEK Stats'
      }
    },
    table: {
      columns: {
        model: 'model',
        serialNumber: 'serial number',
        version: 'version',
        account: 'customer',
        status: 'status',
        bvrServer: 'bvr server',
        lastContact: 'last contacted',
        location: 'location',
        upsStatus: 'UPS status',
        thermalTemp: 'Max Thermal Temp',
        module: 'module',
        firmware: 'firmware',
        lens: 'lens',
        name: 'name',
        service: 'service',
        bound: 'bound',
        groupName: 'group name',
        productNo: 'product no',
        cameraType: 'type',
        cameraName: 'camera name'
      },
      thermalCameraType: {
        optical: 'Optical',
        thermal: 'Thermal'
      }
    },
    emptyTable: {
      title: 'Get Started',
      subTitle: 'By searching {{deviceType}} device'
    },
    modal: {
      action: 'Are you sure to {{action}} this {{entity}}?',
      bind: {
        action: 'Are you sure to bind this device to the'
      },
      unassign: {
        action: 'Are you sure to unassign this device'
      },
      reboot: {
        action: 'Are you sure to reboot this device?'
      }
    },
    '404': {
      title: 'Not Found',
      subTitle: 'Device with this ID has not existed yet',
      subTitleForListing: 'Device type not existed yet'
    },
    UPSEntity: 'APC UPS Monitoring Service Device'
  }
};
