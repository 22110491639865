import { useEOSSContext } from 'context/EOSSContext';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from './serverPage.module.scss';
import { EServerType, Routes } from 'enums';
import { TServerType } from 'models';
import { mapToServerType } from 'utils/common';
import { Result } from 'antd';

export type TLocalServerType = {
  ids: number[];
  name: EServerType;
};

function ServerPageView() {
  const navigate = useNavigate();
  const params = useParams<{ serverType: string }>();
  const [currentServerType, setCurrentServerType] = useState<TServerType | null>(null);
  const { listServerType, refetchListServerType } = useEOSSContext();

  useEffect(() => {
    if (!params.serverType && listServerType.length > 0) {
      const bvrServer = listServerType.find(
        (server) => mapToServerType(server?.name) == EServerType.BVR
      );
      navigate(`${bvrServer?.id}`);
    }
  }, [params, listServerType]);

  useEffect(() => {
    refetchListServerType();
  }, []);

  //TODO temp prevent user goto var and vrs server page
  if (mapToServerType(currentServerType?.name ?? '') !== EServerType.BVR)
    return <Result status="404" title="Something went wrong!" subTitle="Not found!" />;

  return (
    <div className={`h-100 d-flex flex-column ${styles.container}`}>
      <div className={styles.ctnTab}>
        {listServerType.map((serverType) => {
          return (
            <div
              key={serverType.id}
              className={`${styles.tab} ${
                currentServerType?.id === serverType.id ? styles.active : ''
              } ${mapToServerType(serverType.name) !== EServerType.BVR ? 'd-none' : ''}`}
            >
              <Link to={`${Routes.EServerRoutes.INDEX}/${serverType.id}`}>
                <div className={styles.tabName}>{mapToServerType(serverType.name)}</div>
              </Link>
            </div>
          );
        })}
      </div>
      <Outlet
        context={{
          onSetCurrentServerType: (serverType: TServerType) => setCurrentServerType(serverType)
        }}
      />
    </div>
  );
}

export default ServerPageView;
