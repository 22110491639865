import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import React, { memo, useEffect, useMemo, useState } from 'react';
import styles from './pagination.module.scss';

type Props = {
  total: number;
  pageSize?: number;
  initPageNumber?: number;
  className?: string;
  disable?: boolean;
  onChange: (pageNumber: number) => void;
};

const IconLast = memo(({ disable }: { disable: boolean }) => {
  return (
    <svg
      className={disable ? styles.iconDisabled : ''}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1700_2624"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect
          width="24"
          height="24"
          transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 24)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_1700_2624)">
        <path
          d="M12.9996 6L11.5996 7.4L16.1996 12L11.5996 16.6L12.9996 18L18.9996 12L12.9996 6Z"
          fill="#21272F"
        />
        <path
          d="M6.99961 6L5.59961 7.4L10.1996 12L5.59961 16.6L6.99961 18L12.9996 12L6.99961 6Z"
          fill="#21272F"
        />
      </g>
    </svg>
  );
});

const IconPrev = memo(({ disable }: { disable: boolean }) => {
  return (
    <svg
      className={disable ? styles.iconDisabled : ''}
      style={{ transform: 'rotate(-180deg)' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1700_2621"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1700_2621)">
        <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill="#21272F" />
      </g>
    </svg>
  );
});

const IconFirst = memo(({ disable }: { disable: boolean }) => {
  return (
    <svg
      style={{ transform: 'rotate(-180deg)' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={disable ? styles.iconDisabled : ''}
    >
      <mask
        id="mask0_1700_2624"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect
          width="24"
          height="24"
          transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 24)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_1700_2624)">
        <path
          d="M12.9996 6L11.5996 7.4L16.1996 12L11.5996 16.6L12.9996 18L18.9996 12L12.9996 6Z"
          fill="#21272F"
        />
        <path
          d="M6.99961 6L5.59961 7.4L10.1996 12L5.59961 16.6L6.99961 18L12.9996 12L6.99961 6Z"
          fill="#21272F"
        />
      </g>
    </svg>
  );
});

const IconNext = memo(({ disable }: { disable: boolean }) => {
  return (
    <svg
      className={disable ? styles.iconDisabled : ''}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1700_2621"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1700_2621)">
        <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill="#21272F" />
      </g>
    </svg>
  );
});

const Pagination = React.memo(
  ({
    total,
    pageSize = DEFAULT_LIMIT,
    className = '',
    initPageNumber,
    disable,
    onChange
  }: Props) => {
    if (total <= pageSize) return <></>;

    const totalPage = useMemo(
      () => (total > pageSize ? Math.ceil(total / pageSize) : 1),
      [total, pageSize]
    );
    const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE_NUMBER);

    useEffect(() => {
      if (initPageNumber) {
        setCurrentPage(initPageNumber);
      }
    }, [initPageNumber, totalPage]);

    const getPageItemClassName = (currentPage: number, totalPage: number, index: number) => {
      const baseClassName = 'cursor-pointer ' + styles.pageItem;

      if (currentPage === index + 1) {
        return baseClassName + ' ' + styles.actived;
      }

      if ((currentPage === 1 || currentPage === 2) && index + 1 > 5) {
        return baseClassName + ' d-none';
      }

      if (currentPage > 2 && index + 1 > currentPage + 2) {
        return baseClassName + ' d-none';
      }

      if (
        (currentPage === totalPage - 1 || currentPage === totalPage) &&
        index + 1 < totalPage - 4
      ) {
        return baseClassName + ' d-none';
      }

      if (currentPage < totalPage - 1 && index + 1 < currentPage - 2) {
        return baseClassName + ' d-none';
      }

      return baseClassName;
    };

    return (
      <div
        className={`${
          styles.container
        } ${className} flex-column-reverse flex-md-row justify-content-center justify-content-md-end ${
          disable === true ? styles.disabled : ''
        }`}
      >
        <div
          className="d-flex align-items-center justify-content-center justify-content-md-end"
          style={{ flex: 1 }}
        >
          <div
            id="pagination-icon-first"
            className="cursor-pointer d-flex"
            onClick={() => {
              onChange(1);
              setCurrentPage(1);
            }}
          >
            <IconFirst disable={currentPage === 1} />
          </div>
          <div
            id="pagination-icon-prev"
            onClick={() => {
              onChange(currentPage === 1 ? 1 : currentPage - 1);
              setCurrentPage((prev) => {
                return prev === 1 ? 1 : --prev;
              });
            }}
            className="mx-2 cursor-pointer d-flex"
          >
            <IconPrev disable={currentPage === 1} />
          </div>
          <div className={`${styles.pageNumber} d-flex gap-2`}>
            {[...Array(totalPage)].map((_, index) => {
              return (
                <div
                  id={`pagination-page-${index + 1}`}
                  className={getPageItemClassName(currentPage, totalPage, index)}
                  key={index + 1}
                  onClick={() => {
                    onChange(index + 1);
                    setCurrentPage(index + 1);
                  }}
                >
                  {index + 1}
                </div>
              );
            })}
          </div>
          <div
            id="pagination-icon-next"
            className="mx-2 cursor-pointer d-flex"
            onClick={() => {
              onChange(currentPage === totalPage ? totalPage : currentPage + 1);
              setCurrentPage((prev) => {
                return prev === totalPage ? totalPage : ++prev;
              });
            }}
          >
            <IconNext disable={currentPage === totalPage} />
          </div>
          <div
            id="pagination-icon-last"
            className="cursor-pointer d-flex"
            onClick={() => {
              onChange(totalPage);
              setCurrentPage(totalPage);
            }}
          >
            <IconLast disable={currentPage === totalPage} />
          </div>
        </div>
        <div className={styles.showTotal}>
          {pageSize * currentPage - pageSize + 1} -{' '}
          {pageSize * currentPage > total ? total : pageSize * currentPage} of {total}
        </div>
      </div>
    );
  }
);

export { Pagination };
