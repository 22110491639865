import { DomainGeneralPageView } from './components/DomainGeneralPageView';

function EditDomainPageView() {
  return (
    <>
      <DomainGeneralPageView editMode={true} />
    </>
  );
}

export default EditDomainPageView;
