import { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import styles from './collapseDivider.module.scss';
import { Tooltip } from 'antd';
import useResponsive from 'hooks/useResponsive';
import { CollapseButton } from 'assets/icons';
import { useHotkeys } from 'hooks/useHotKeys';
import { useTranslation } from 'react-i18next';

type Props = {
  leftColumnOpen?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onSideBarClick?: () => void;
};

const CollapseDivider = forwardRef(
  ({ leftColumnOpen, className, style, onSideBarClick }: Props, ref: ForwardedRef<unknown>) => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState<boolean>(false);
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

    const { isDesktop } = useResponsive();

    useImperativeHandle(ref, () => {
      return {
        onHideTooltip: () => {
          setVisible(false);
        },
        onShowTooltip: () => {
          setVisible(true);
        }
      };
    });

    useHotkeys({
      BracketLeft: () => {
        isDesktop && onSideBarClick && onSideBarClick();
        setTooltipVisible(false);
      }
    });

    if (!isDesktop) {
      return <></>;
    }

    return (
      <div
        className={`${styles.divider} position-relative ${className}`}
        style={{
          ...style,
          opacity: visible || !leftColumnOpen ? 1 : 0,
          margin: '0 10px',
          left: leftColumnOpen ? -10 : 0
        }}
      >
        <Tooltip
          title={() =>
            leftColumnOpen && isDesktop ? (
              <span>
                {`${t('components.collapse')} `}
                <span className={styles.hotKeyBtn}>{'['}</span>
              </span>
            ) : (
              <span>
                {`${t('components.expand')} `}
                <span className={styles.hotKeyBtn}>{'['}</span>
              </span>
            )
          }
          destroyTooltipOnHide
          open={tooltipVisible}
          placement="right"
        >
          <CollapseButton
            className={`${styles.collapseButton} position-absolute`}
            isReverse={leftColumnOpen}
            onClick={() => {
              onSideBarClick && onSideBarClick();
              setTooltipVisible(false);
            }}
            onMouseEnter={() => {
              setVisible(true);
              setTooltipVisible(true);
            }}
            onMouseMove={() => setVisible(true)}
            onMouseLeave={() => {
              setVisible(false);
              setTooltipVisible(false);
            }}
          />
        </Tooltip>
      </div>
    );
  }
);

export { CollapseDivider };
