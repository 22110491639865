import { useEffect, useState } from 'react';
import { Section } from 'presentation/components';
import { Table } from 'antd';
import { TPermission } from 'models';
import { ColumnsType } from 'antd/es/table';
import { useEOSSContext } from 'context/EOSSContext';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import useResponsive from 'hooks/useResponsive';
import styles from './tablePermission.module.scss';

function PermissionPageView() {
  const { isDesktop } = useResponsive();
  const { listPermission, refetchListPermission } = useEOSSContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const columns: ColumnsType<TPermission> = [
    {
      title: t('systemPage.table.columns.id'),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (val) => (
        <span className="fs-13 fw-medium" style={{ color: 'var(--primary-1)' }}>
          {val}
        </span>
      )
    },
    {
      title: t('systemPage.table.columns.name'),
      dataIndex: 'displayName',
      key: 'displayName',
      render: (val) => (
        <span className="fs-13 fw-medium" style={{ color: 'var(--primary-1)' }}>
          {val}
        </span>
      )
    }
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refetchListPermission();
      setIsLoading(false);
    })();
  }, []);

  return (
    <Section title={t('systemPage.sections.permission.title')}>
      <Table
        className={styles.container}
        loading={isLoading}
        pagination={false}
        tableLayout="auto"
        columns={columns}
        rowKey="id"
        scroll={
          listPermission.length > 0
            ? {
                x: 'auto',
                y: `calc(var(--app-height) - ${isDesktop ? '261px' : '251px'})` // 237px | 227px: not magic number (header 57px + breadcrumb 28px + padding 16px + header section 30px + header table 40px + pagination 44px + 30px (more space))
              }
            : {
                x: 'min-content'
              }
        }
        dataSource={isLoading ? [] : orderBy(listPermission, 'id', 'asc')}
        rowClassName={(_, index) => (index % 2 ? 'row-even' : 'row-odd')}
      />
    </Section>
  );
}

export default PermissionPageView;
