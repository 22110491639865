import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { TEyeviewConnection } from 'models/EyeviewSession';
import { capitalize } from 'lodash';
import { useAppUtil } from 'context/UtilContext';

type Props = {
  isLoading: boolean;
  listUsers: TEyeviewConnection[];
  onClickLogout: (userId: string) => void;
};

function TableListEyeviewUserSessions({ isLoading, listUsers, onClickLogout }: Props) {
  const { t } = useTranslation();
  const { openModal } = useAppUtil();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const columns: ColumnsType<TEyeviewConnection> = useMemo(() => {
    return [
      {
        title: () => t('eyeviewSessionPage.table.columns.name'),
        dataIndex: 'login',
        key: 'login',
        render: (value) => {
          return value;
        },
        width: '20%'
      },
      {
        title: () => t('eyeviewSessionPage.table.columns.guid'),
        dataIndex: 'id',
        key: 'id',
        render: (value) => {
          return value;
        },
        width: '20%'
      },
      {
        title: () => t('eyeviewSessionPage.table.columns.account'),
        dataIndex: 'subscriber',
        key: 'subscriber',
        render: (value) => {
          return value;
        },
        width: '20%'
      },
      {
        title: () => t('eyeviewSessionPage.table.columns.logout'),
        render: (value, record) => {
          return (
            <Button
              onClick={() => {
                if (isSubmitting) return;
                setIsSubmitting(true);
                openModal({
                  title: t('components.confirmationTitle'),
                  content: t('components.confirmationMessage', {
                    action: t('components.logout').toLowerCase(),
                    entity: t('header.user').toLowerCase()
                  }),
                  okText: `${t('components.ok')}`,
                  cancelText: `${t('components.cancel')}`,
                  onOk: () => {
                    onClickLogout(record.id);
                    setIsSubmitting(false);
                  },
                  onCancel: () => setIsSubmitting(false)
                });
              }}
              className="fs-14 fw-semibold rounded-5 px-4"
              style={{ border: '1px solid var(--sub-3)', color: 'var(--sub-3)' }}
            >
              {capitalize(t('components.logout'))}
            </Button>
          );
        }
      }
    ];
  }, [isSubmitting]);

  return (
    <Table
      loading={isLoading}
      rowKey={'id'}
      tableLayout="auto"
      dataSource={listUsers}
      columns={columns}
      scroll={{
        x: 'auto'
      }}
      pagination={false}
    />
  );
}

export default TableListEyeviewUserSessions;
