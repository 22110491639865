import React, { useMemo } from 'react';
import { useServicePageController } from './ServicePageController';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Section } from 'presentation/components';
import { ExpandIcon } from 'assets/icons';
import styles from './servicePage.module.scss';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';

interface DataTypeService {
  id: number;
  key?: string;
  children?: DataTypeService[];
  name: string;
}
function ServicePageView() {
  const { listServices, isLoading } = useServicePageController();
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();
  const columns: ColumnsType<DataTypeService> = useMemo(
    () => [
      {
        title: t('systemPage.table.columns.service'),
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => (
          <span
            data-name="serviceName"
            className={`align-middle fs-13 ${
              record.key && !record.key.includes('::') ? 'fw-medium' : styles.nameSubService
            }`}
          >
            {value}
          </span>
        )
      },
      {
        title: t('systemPage.table.columns.comment'),
        dataIndex: 'code',
        key: 'code',
        render: (value, record) => (
          <span
            className={`align-middle fs-13 ${
              record.key && !record.key.includes('::') ? 'fw-medium' : ''
            }`}
          >
            {value}
          </span>
        )
      }
    ],
    []
  );

  return (
    <div className={`${styles.container}`}>
      <Section title={t('systemPage.sections.service.title')}>
        <Table
          loading={isLoading}
          tableLayout="auto"
          columns={columns}
          dataSource={isLoading ? [] : listServices}
          rowClassName={(record) => {
            return record.key && !record.key.includes('::') ? styles.parentRow : styles.childrenRow;
          }}
          scroll={
            listServices.length > 0
              ? {
                  x: 'auto',
                  y: `calc(var(--app-height) - ${isDesktop ? '261px' : '251px'})` // 237px | 227px: not magic number (header 57px + breadcrumb 28px + padding 16px + header section 30px + header table 40px + pagination 44px + 30px (more space))
                }
              : {
                  x: 'min-content'
                }
          }
          pagination={false}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              return (
                <span
                  className={`me-2 ${!record.children ? 'invisible' : 'visible cursor-pointer'}`}
                  onClick={(e) => record.children && onExpand(record, e)}
                >
                  <ExpandIcon expanded={expanded} isBlue />
                </span>
              );
            }
          }}
          indentSize={40}
        />
      </Section>
    </div>
  );
}

export default ServicePageView;
