import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ERoleKey, EStorageKey, ESubFeatureKey, Routes } from 'enums';
import { usePathPermission } from 'hooks/usePermission';
import { orderBy } from 'lodash';
import { TEyeviewUserAssign } from 'models';
import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './tableEyeviewUserAssignment.module.scss';
import useResponsive from 'hooks/useResponsive';

type Props = {
  maxHeight?: number;
  userAssignmentList: {
    data: TEyeviewUserAssign[];
    loading: boolean;
  };
  eyeviewUserSelectedRow: React.Key[];
  onEyeviewUserSelectedRowChange: (value: React.Key[]) => void;
};

const PADDING_BOTTOM = 4;

function TableEyeviewUserAssignment({
  maxHeight,
  userAssignmentList,
  eyeviewUserSelectedRow,
  onEyeviewUserSelectedRowChange
}: Props) {
  const { t } = useTranslation();

  const { isDesktop } = useResponsive();

  const haveCameraGroupUpdateAssignmentPermission = usePathPermission(
    Routes.ECameraGroupRoutes.LISTING,
    ERoleKey.UPDATE,
    ESubFeatureKey.ASSIGNMENT
  ).allowed;

  const columnsEyeviewUserAssignment: ColumnsType<TEyeviewUserAssign> = useMemo(
    () => [
      {
        title: t('cameraGroupPage.eyeviewUserTable.eyeviewUser'),
        key: 'username',
        dataIndex: 'username',
        render: (value, record) => (
          <Link
            className="text-wrap"
            to={`${Routes.EUserRoutes.LISTING}/${record.id}?search=${value}`}
          >
            {value}
          </Link>
        )
      }
    ],
    []
  );

  function checkGroupIsNew(id: number): boolean {
    const getItem = sessionStorage.getItem(EStorageKey.EYEVIEW_USER_ASSIGNMENT_KEYS);
    const listKeys: Array<number> = getItem ? JSON.parse(getItem) : [];
    if (listKeys.length > 0) {
      return Boolean(listKeys.find((key) => Number(key) === id));
    }
    return false;
  }

  useEffect(() => {
    const findRow = document.querySelector('.new-group');
    if (findRow) {
      findRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [userAssignmentList]);

  return (
    <Table
      id="table-eyeview-user-assignment-list-device-group-detail"
      tableLayout="auto"
      className={styles.container}
      dataSource={orderBy(userAssignmentList.data, 'username', 'asc')}
      loading={userAssignmentList.loading}
      rowKey={(record) => record.id}
      columns={columnsEyeviewUserAssignment}
      rowSelection={{
        columnTitle: t('cameraGroupPage.otherDeviceTable.assigned').toUpperCase(),
        type: 'checkbox',
        hideSelectAll: true,
        columnWidth: 100,
        selectedRowKeys: eyeviewUserSelectedRow,
        onChange: onEyeviewUserSelectedRowChange,
        getCheckboxProps: () => ({
          disabled: !haveCameraGroupUpdateAssignmentPermission
        })
      }}
      scroll={{
        y: isDesktop && maxHeight ? maxHeight + PADDING_BOTTOM : undefined
      }}
      rowClassName={(record, index) => {
        return `${index % 2 ? 'row-even' : 'row-odd'} ${
          checkGroupIsNew(record.id) ? 'new-group' : ''
        }`;
      }}
      pagination={false}
    />
  );
}

export default memo(TableEyeviewUserAssignment);
