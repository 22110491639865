import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './systemPage.module.scss';
import { Routes } from 'enums';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function SystemPageView() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTabIdx, setCurrentTabIdx] = useState<number>(0);
  const refsMenu = useRef<Array<HTMLDivElement | null>>([]);

  const systemTabs = useMemo(
    () => [
      { id: Routes.ESystemRoutes.SERVICE, name: t('systemPage.tab.service') },
      { id: Routes.ESystemRoutes.PERMISSION, name: t('systemPage.tab.permission') },
      { id: Routes.ESystemRoutes.DEVICE_TYPE, name: t('systemPage.tab.deviceType') },
      { id: Routes.ESystemRoutes.ACCOUNT_TYPE, name: t('systemPage.tab.accountType') },
      { id: Routes.ESystemRoutes.SERVER_TYPE, name: t('systemPage.tab.serverType') }
    ],
    []
  );

  useEffect(() => {
    const findTabUrl = location.pathname.split('/')[2];
    if (!findTabUrl) {
      navigate(Routes.ESystemRoutes.SERVICE);
    } else {
      setCurrentTabIdx(systemTabs.findIndex((tab) => tab.id.includes(findTabUrl)));
    }
  }, [location.pathname, systemTabs]);

  useEffect(() => {
    if (currentTabIdx && refsMenu) {
      refsMenu.current[currentTabIdx]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }, [refsMenu, currentTabIdx]);

  return (
    <div className={`h-100 d-flex flex-column  ${styles.container}`}>
      <div className={styles.ctnTab}>
        {systemTabs.map((tab, index) => {
          const nextItem = currentTabIdx + 1;
          const prevItem = currentTabIdx - 1;
          return (
            <div
              ref={(element) => {
                refsMenu.current[index] = element;
              }}
              data-position={nextItem === index ? 'next' : prevItem === index ? 'prev' : ''}
              key={tab.id}
              className={`${styles.tab} ${index === currentTabIdx ? styles.active : ''}`}
            >
              <Link to={`${tab.id}`} onClick={() => setCurrentTabIdx(index)}>
                <div className={styles.tabName}>{tab.name}</div>
              </Link>
            </div>
          );
        })}
      </div>
      <div className="wrap-container overflow-hidden h-100">
        <div className=" h-100 overflow-y-auto custom-scrollbar">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SystemPageView;
