import { EDeviceType } from 'enums';

export * from './paging';
export * from './notification';
export * from './dateFormat';
export * from './regex';
export * from './font';

export const MAXIMUM_4_CHARACTERS = 4;
export const MAXIMUM_8_CHARACTERS = 8;
export const MAXIMUM_10_CHARACTERS = 10;
export const MAXIMUM_16_CHARACTERS = 16;
export const MAXIMUM_32_CHARACTERS = 32;
export const MAXIMUM_64_CHARACTERS = 64;
export const MAXIMUM_128_CHARACTERS = 128;
export const MAXIMUM_256_CHARACTERS = 256;

export const MOBILE_BREAKPOINT = 576;
export const SEMI_TABLET_BREAKPOINT = 768;
export const DESKTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1024;
export const SEMI_DESKTOP_BREAKPOINT = 1440;

export const TOOLTIP_DELAY = 0.25;
export const DECOMMISSIONED = 'Decommissioned';

export const MAXIMUM_DRAM_UTIL = 60;

export const IP_CAMERA_SERVICE_PACKAGE_NAME = 'IPCam Service Packages';
export const THERMAL_CAMERA_SERVICE_PACKAGE_NAME = 'Thermal Camera Service Package';
export const THERMAL_ANALYTIC_SERVICE_PACKAGE_NAME = 'Thermal Analytics';

export const DEVICE_TYPE = {
  [EDeviceType.THERMAL_CAMERA]: 'Thermal',
  [EDeviceType.IP_CAMERA]: 'Optical',
  [EDeviceType.ANALOG_CAMERA]: '',
  [EDeviceType.UPS_MONITOR]: '',
  [EDeviceType.THERMAL_OPTICAL_CAMERA]: '',
  [EDeviceType.ASTRO_CAMERA]: ''
};
