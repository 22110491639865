import { Form } from 'antd';
import { useEOSSContext } from 'context/EOSSContext';
import { useLoaderContext } from 'context/LoaderContext';
import { useAppUtil } from 'context/UtilContext';
import { EAdminType, EFeatureKey, ERoleKey, EStatusInvalid, Routes } from 'enums';
import { orderBy } from 'lodash';
import uniq from 'lodash/uniq';
import { TDomain, TPermission } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AdministratorRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError, isJsonString } from 'utils/common';

type FieldType = {
  adminType: string;
  domain?: number;
  login: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  street?: string;
  city?: string;
  language: number;
  state?: string;
  zip?: string;
  comment?: string;
  invalid: number;
};

export const useAdminCreatePageController = () => {
  const [form] = Form.useForm<FieldType>();
  const administratorRepository = AdministratorRepository(AxiosClient);
  const {
    listPermission,
    listLanguage,
    listAdminType,
    refetchListPermission,
    refetchListLanguage,
    refetchListAdminType
  } = useEOSSContext();
  const { loader } = useLoaderContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentAdminTypeValue = Form.useWatch('adminType', form);
  const currentAdminType = listAdminType.find((type) => type?.name === currentAdminTypeValue);
  const [listPermissionsAdditional, setListPermissionsAdditional] = useState<TPermission[]>([]);
  const [listDomains, setListDomains] = useState<TDomain[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDomainAdminType = currentAdminType?.name === EAdminType.DOMAIN_ADMIN;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openNotification, openModal } = useAppUtil();

  const splitByFeature = (permissionKey: string) => {
    const arrFeature = [
      EFeatureKey.ADMIN,
      EFeatureKey.DOMAIN,
      EFeatureKey.ACCOUNT,
      EFeatureKey.DEVICE,
      EFeatureKey.CAMERA_GROUP,
      EFeatureKey.USER,
      EFeatureKey.EYEVIEW_SESSIONS,
      EFeatureKey.SERVER,
      EFeatureKey.SYSTEM
    ];
    const feature = arrFeature.find((f) => permissionKey.includes(f + '_'));
    if (feature) {
      return feature;
    }
  };

  function handleSubmit(values: FieldType) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    openModal({
      title: t('components.confirmationTitle'),
      content: t('adminUserPage.modal.action', {
        action: t('components.create').toLowerCase(),
        entity: t('header.admin').toLowerCase()
      }),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      onOk: async () => {
        const adminTypeId = currentAdminType?.id;
        if (!adminTypeId) return;
        try {
          const addressData = JSON.stringify({
            street: values.street?.trim(),
            city: values.city?.trim(),
            state: values.state?.trim(),
            zip: values.zip?.trim()
          });

          const contactData = JSON.stringify({
            phone: values.phone?.trim(),
            email: values.email.trim()
          });

          const addMorePermissionsView = uniq(
            listPermissionsAdditional
              .filter((permission) => {
                return !permission.name.includes(ERoleKey.VIEW);
              })
              .map((permission) => {
                const permissionView = `${splitByFeature(permission.name)}_${ERoleKey.VIEW}`;
                const findPermission = listPermission.find((p) => p?.name === permissionView);
                return findPermission;
              })
          );

          const addMorePermissionsUpdate = uniq(
            listPermissionsAdditional
              .filter((permission) => {
                return permission.name.includes(ERoleKey.UPDATE);
              })
              .map((permission) => {
                const permissionView = `${splitByFeature(permission.name)}_${ERoleKey.UPDATE}`;
                const findPermission = listPermission.find((p) => p?.name === permissionView);
                return findPermission;
              })
          );

          const adhocPermissions = uniq(
            [...listPermissionsAdditional, ...addMorePermissionsView, ...addMorePermissionsUpdate]
              .filter(
                (permission) =>
                  !currentAdminType.permissions.some(
                    (permissionInAdminType) => permissionInAdminType.id === permission?.id
                  )
              )
              .map((permission) => permission?.id)
          );

          const res = await administratorRepository.createAdminUser({
            adminTypeId: adminTypeId,
            address: isJsonString(addressData) ? addressData : null,
            firstName: values.firstName.trim(),
            languageId: values.language,
            lastName: values.lastName.trim(),
            password: values.password,
            username: values.login.trim(),
            comment: values.comment?.trim() || null,
            contact: contactData,
            domainId: values.domain,
            invalid: values.invalid,
            adhocPermissionIds: adhocPermissions.length > 0 ? adhocPermissions.toString() : ''
          });
          if (res) {
            // TODO: Double Check Localization Here
            openNotification({
              type: 'success',
              title: 'Create Admin User',
              description: `${t('components.success')}`
            });
            searchParams.set('search', values.login.trim());
            setSearchParams(searchParams);
            navigate({
              pathname: Routes.EAdminRoutes.LISTING,
              search: searchParams.toString()
            });
          }
        } catch (error) {
          const message = handleApiError({
            apiErrorResponse: error as ApiErrorResponse,
            action: 'create',
            entity: t('adminUserPage.entity'),
            identifier: values.login,
            t
          });
          openNotification({
            type: 'error',
            title: `${t('actions.create')} ${t('adminUserPage.entity')}`,
            description: message
          });
        } finally {
          setIsSubmitting(false);
        }
      },
      styles: {
        content: {
          width: 'auto'
        }
      },
      width: 'fit-content',
      onCancel: () => {
        setIsSubmitting(false);
      }
    });
  }

  async function handleGetListDomains() {
    try {
      loader.start();
      const res = await administratorRepository.getListDomains();
      const listDomainValid = orderBy(
        res
          .filter((domain) => domain.invalid === EStatusInvalid.VALID)
          .map((domain) => ({ ...domain, name: domain.name.trim() })),
        'name',
        'asc'
      );
      if (listDomainValid.length > 0) {
        form.setFieldValue('domain', listDomainValid[0].id);
      }
      setListDomains(listDomainValid);
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('forms.domain.label'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('forms.domain.label')}`,
        description: message
      });
    } finally {
      loader.complete();
    }
  }

  useEffect(() => {
    refetchListPermission();
    refetchListLanguage();
    refetchListAdminType();
  }, []);

  useEffect(() => {
    if (isDomainAdminType) {
      handleGetListDomains();
    } else {
      form.setFieldValue('domain', undefined);
    }
  }, [isDomainAdminType]);

  useEffect(() => {
    setListPermissionsAdditional([]);
  }, [currentAdminType]);

  useEffect(() => {
    if (listLanguage.length > 0) {
      form.setFieldValue('language', listLanguage[0].id);
    }
  }, [listLanguage]);

  useEffect(() => {
    if (listAdminType.length > 0) {
      form.setFieldValue('adminType', listAdminType[0].name);
    }
  }, [listAdminType]);

  return {
    form,
    listDomains,
    listLanguage,
    listPermission,
    isDomainAdminType,
    currentAdminType,
    listAdminType,
    listPermissionsAdditional,
    handleSubmit,
    setListPermissionsAdditional
  };
};
