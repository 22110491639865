import { EHttpStatusCode, ESideBarState, EStorageKey } from 'enums';
import { TError, TPermission } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { getError } from 'utils/common';
import { Either, Left, Right } from 'utils/either';

type TLoginParams = { username: string; password: string };

interface IAuthContext {
  isLogged: boolean;
  permissions: TPermission[];
  login: (params: TLoginParams) => Promise<Either<ApiErrorResponse | undefined, void>>;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const authRepository = AuthRepository(AxiosClient);
  const eossToken = localStorage.getItem(EStorageKey.EOSS_TOKEN);
  const [isLogged, setIsLogged] = useState(!!eossToken);
  const [myPermissions, setMyPermissions] = useState<TPermission[]>([]);

  async function handleLogin(
    params: TLoginParams
  ): Promise<Either<ApiErrorResponse | undefined, void>> {
    const { username, password } = params;
    const dataResponseOrError = await authRepository.login({ username, password });

    if (dataResponseOrError.isLeft()) {
      return Left.create(dataResponseOrError.error);
    }

    const { data } = dataResponseOrError.value;

    localStorage.setItem(
      EStorageKey.EOSS_CURRENT_USER,
      JSON.stringify({
        username: data.username,
        adminTypeDisplayName: data.adminTypeDisplayName,
        language: data.language
      })
    );

    // Init Left Sidebar State

    if (localStorage.getItem(EStorageKey.EOSS_LEFT_SIDE_BAR_STATE) === null) {
      localStorage.setItem(EStorageKey.EOSS_LEFT_SIDE_BAR_STATE, ESideBarState.OPEN);
    }

    setIsLogged(true);

    return Right.create(undefined);
  }

  async function handleGetProfile() {
    try {
      const { code, data } = await authRepository.getProfile();
      if (code === EHttpStatusCode.OK) {
        setMyPermissions(data.permissions);
      }
    } catch (error) {
      const err = getError<TError>(error as string);
      return {
        success: false,
        error: err.message
      };
    }
  }

  function handleLogout() {
    localStorage.clear();
    sessionStorage.clear();
    setMyPermissions([]);
    setIsLogged(false);
  }

  useEffect(() => {
    setIsLogged(!!eossToken);
  }, [eossToken]);

  useEffect(() => {
    function detectVisibilityChange(e: any) {
      const visible = e.target.visibilityState === 'visible';
      if (visible) {
        const token = localStorage.getItem(EStorageKey.EOSS_TOKEN);
        if (!token && !window.location.href.includes('/login')) {
          window.location.reload();
        }
      }
    }

    window.onfocus = () => {
      const token = localStorage.getItem(EStorageKey.EOSS_TOKEN);
      if (!token && !window.location.href.includes('/login')) {
        window.location.reload();
      }
    };

    window.addEventListener('visibilitychange', detectVisibilityChange, false);

    return () => {
      window.removeEventListener('visibilitychange', detectVisibilityChange, false);
    };
  }, []);

  useEffect(() => {
    if (isLogged) {
      handleGetProfile();
    }
  }, [isLogged]);

  const value: IAuthContext = {
    isLogged: isLogged,
    permissions: myPermissions,
    logout: handleLogout,
    login: handleLogin
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  return useContext(AuthContext);
}
