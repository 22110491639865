import { TSortOrder, TServer, TPaginationEOSS } from 'models';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { SortIcon } from 'presentation/components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from 'constant';
import { checkDeviceStatus } from 'utils/common';
import useResponsive from 'hooks/useResponsive';
import styles from './listServer.module.scss';
import { EDeviceStatus } from 'enums';

type Props = {
  isLoading: boolean;
  listServers: TServer[];
  sortKey: string | null;
  order: TSortOrder;
  searchText: string | null;
  onChangeSort?: (order?: string | null, columnKey?: string) => void;
  paging: TPaginationEOSS;
};

function TableListServer({
  listServers,
  sortKey,
  order,
  onChangeSort,
  isLoading,
  searchText,
  paging
}: Props) {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();

  const columns: ColumnsType<TServer> = useMemo(() => {
    return [
      {
        title: () => t('serverPage.table.columns.serialNumber'),
        dataIndex: 'serialNumber',
        key: 'serialNumber',
        render: (value, record) => {
          return (
            <Link
              data-name="serialNumber"
              to={`${record.serialNumber}${
                searchText ? `?search=${searchText}&page=${paging.pageNum}` : ''
              }`}
            >
              {value}
            </Link>
          );
        }
      },
      {
        title: () => t('serverPage.table.columns.version'),
        dataIndex: 'softwareVersion',
        key: 'softwareVersion',
        sortIcon: SortIcon,
        sortOrder: sortKey === 'softwareVersion' ? order : null,
        sorter: (a, b) => (a.softwareVersion?.length || 0) - (b.softwareVersion?.length || 0),
        render: (value) => {
          return <div data-name="softwareVersion">{value}</div>;
        }
      },
      {
        title: () => t('serverPage.table.columns.serverName'),
        dataIndex: 'name',
        key: 'name',
        render: (value) => {
          return <div data-name="name">{value}</div>;
        }
      },
      {
        title: () => t('serverPage.table.columns.usage'),
        dataIndex: 'usage',
        key: 'usage',
        render: (value) => {
          return <div data-name="usage">{value}</div>;
        }
      },
      {
        title: () => (
          <div className="text-nowrap">
            {t('serverPage.table.columns.accountDevices.customer')}
            {' / '}
            {t('serverPage.table.columns.accountDevices.devices')}
          </div>
        ),
        dataIndex: 'deviceCount',
        key: 'deviceCount',
        render: (value) => {
          return <span data-name="customerDevices">{`- / ${value}`}</span>;
        }
      },
      {
        title: () => t('serverPage.table.columns.status'),
        dataIndex: 'status',
        key: 'status',
        sortIcon: SortIcon,
        sortOrder: sortKey === 'status' ? order : null,
        sorter: (a, b) => (a.lastContact ?? 0) - (b.lastContact ?? 0),
        render: (value, record) => {
          if (checkDeviceStatus(record.lastContact)) {
            return (
              <span className="fw-medium" data-name="status" style={{ color: 'var(--sub-5)' }}>
                {EDeviceStatus.ONLINE}
              </span>
            );
          }
          return (
            <span className="fw-medium" data-name="status" style={{ color: 'var(--sub-3)' }}>
              {EDeviceStatus.OFFLINE}
            </span>
          );
        }
      },
      {
        title: () => t('serverPage.table.columns.ipAddress'),
        dataIndex: 'ip',
        key: 'ip',
        render: (value) => <div data-name="ip">{value}</div>
      },
      {
        title: () => t('serverPage.table.columns.lastContacted'),
        dataIndex: 'lastContact',
        key: 'lastContact',
        sortIcon: SortIcon,
        sortOrder: sortKey === 'lastContact' ? order : null,
        sorter: (a, b) => (a.lastContact ?? 0) - (b.lastContact ?? 0),
        render: (value: number) => (
          <span data-name="lastContact" className="text-nowrap">
            {value ? dayjs(dayjs.unix(value)).format(DATETIME_FORMAT) : ''}
          </span>
        )
      }
    ];
  }, [order, sortKey, searchText, paging]);

  return (
    <Table
      loading={isLoading}
      rowKey={'id'}
      dataSource={listServers}
      columns={columns}
      className={styles.container}
      rowClassName={(record, index) => {
        return `${index % 2 ? 'row-even' : 'row-odd'}`;
      }}
      scroll={
        listServers.length > 0
          ? {
              x: 'auto',
              y: `calc(var(--app-height) - ${isDesktop ? '261px' : '251px'})` // 237px | 227px: not magic number (header 57px + breadcrumb 28px + padding 16px + header section 30px + header table 40px + pagination 44px + 30px (more space))
            }
          : {
              x: 'min-content'
            }
      }
      tableLayout="auto"
      pagination={{
        pageSize: paging.pageLimit,
        current: paging.pageNum,
        total: paging.total
      }}
      onChange={(pagination, filters, sorter) => {
        const sorterValue = sorter as SorterResult<TServer>;
        onChangeSort && onChangeSort(sorterValue.order, sorterValue.columnKey?.toString());
      }}
    />
  );
}

export default TableListServer;
