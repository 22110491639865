import { useMemo } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import type { TAccount, TPaginationEOSS, TSortOrder } from 'models';
import { SmartTooltip, SortIcon } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import { Link, useSearchParams } from 'react-router-dom';
import { EStatusInvalid, EStatusString, Routes } from 'enums';
import styles from './tableListAccount.module.scss';
import { SorterResult } from 'antd/es/table/interface';
import { TOOLTIP_DELAY } from 'constant';

type Props = {
  searchKey: string | null;
  accountList: {
    data: TAccount[];
    loading: boolean;
    paging: TPaginationEOSS;
  };
};

const TableListAccount = ({ searchKey, accountList }: Props) => {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();

  const [searchParams, setSearchParams] = useSearchParams();

  const sortKey = searchParams.get('sortKey');
  const page = searchParams.get('page');
  const accountSortOrder =
    sortKey === 'accountNumber' ? (searchParams.get('order') as TSortOrder | undefined) : undefined;
  const validSortOrder =
    sortKey === 'invalid' ? (searchParams.get('order') as TSortOrder | undefined) : undefined;

  const columns: ColumnsType<TAccount> = useMemo(
    () => [
      {
        title: t('accountPage.accountListingTable.account').toUpperCase(),
        dataIndex: 'accountNumber',
        key: 'accountNumber',
        sortIcon: SortIcon,
        sortOrder: accountSortOrder,
        sorter: (a: TAccount, b: TAccount) =>
          (a.accountNumber ?? a.id ?? 0) - (b.accountNumber ?? b.id ?? 0),
        render: (value, record) => {
          return (
            <Link
              data-name="accountNumber"
              to={`${Routes.EAccountRoutes.LISTING}/${record.id}${
                searchKey ? `?search=${searchKey}&page=${page}` : ''
              }`}
            >
              {value ?? record.id}
            </Link>
          );
        }
      },
      {
        title: t('accountPage.accountListingTable.domain').toUpperCase(),
        dataIndex: 'domain',
        key: 'domain',
        render: (value) => {
          return <span data-name="domain">{value.name}</span>;
        }
      },
      {
        title: t('accountPage.accountListingTable.name').toUpperCase(),
        dataIndex: 'name',
        key: 'name',
        render: (value) => {
          return <span data-name="name">{value}</span>;
        }
      },
      {
        title: t('accountPage.accountListingTable.accountType').toUpperCase(),
        dataIndex: 'accountType',
        key: 'accountType',
        render: (value) => {
          return <span data-name="accountType">{value.name}</span>;
        }
      },
      {
        title: t('accountPage.accountListingTable.valid').toUpperCase(),
        dataIndex: 'invalid',
        key: 'invalid',
        sortIcon: SortIcon,
        sorter: (a: TAccount, b: TAccount) => (a.invalid ?? 0) - (b.invalid ?? 0),
        sortOrder: validSortOrder,
        render: (value) => (
          <span
            data-name="invalid"
            className={`${Number(value) === EStatusInvalid.VALID ? styles.success : styles.error} `}
          >
            {Number(value) === EStatusInvalid.VALID ? EStatusString.VALID : EStatusString.INVALID}
          </span>
        )
      },
      {
        title: t('accountPage.accountListingTable.comment').toUpperCase(),
        dataIndex: 'comment',
        key: 'comment',
        render: (value) => {
          return (
            <SmartTooltip
              name="comment"
              delayTime={TOOLTIP_DELAY}
              TypographyComponentProps={{
                style: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }}
              TooltipComponentProps={{
                trigger: 'hover'
              }}
            >
              {value}
            </SmartTooltip>
          );
        }
      }
    ],
    [accountSortOrder, validSortOrder, sortKey, searchKey, page]
  );

  return (
    <Table
      className={styles.container}
      tableLayout="auto"
      dataSource={accountList.data}
      loading={accountList.loading}
      rowKey={(record) => record.id}
      columns={columns}
      rowClassName={(_, index) => (index % 2 ? 'row-even' : 'row-odd')}
      scroll={
        accountList.data.length > 0
          ? {
              x: 'auto',
              y: `calc(var(--app-height) - ${isDesktop ? '261px' : '251px'})` // 237px | 227px: not magic number (header 57px + breadcrumb 28px + padding 16px + header section 30px + header table 40px + pagination 44px + 30px (more space))
            }
          : {
              x: 'min-content'
            }
      }
      pagination={{
        pageSize: accountList.paging.pageLimit,
        current: accountList.paging.pageNum,
        total: accountList.paging.total
      }}
      onChange={(_, __, sorter: SorterResult<TAccount> | SorterResult<TAccount>[]) => {
        const { order, columnKey } = sorter as SorterResult<TAccount>;
        if (columnKey && order) {
          searchParams.set('sortKey', columnKey.toString());
          searchParams.set('order', order);
        } else {
          searchParams.delete('sortKey');
          searchParams.delete('order');
        }
        setSearchParams(searchParams);
      }}
    />
  );
};

export { TableListAccount };
