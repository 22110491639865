import React from 'react';
import { Form, Input, Select } from 'antd';
import { DropdownIcon } from 'assets/icons';
import type { TCameraGroupGeneralFieldType, TSelect } from '../cameraGroupPage.types';
import { useTranslation } from 'react-i18next';
import styles from './generalForm.module.scss';
import ValidationUtilities from 'utils/validationUtils';
import { orderBy } from 'lodash';

type Props<T, K> = {
  editMode?: boolean;
  className?: string;
  accountList?: {
    data: K[];
    loading: boolean;
  };
  cameraGroupTypes?: {
    data: T[];
    loading: boolean;
  };
};

const General = <T extends AnyObject, K extends AnyObject>({
  editMode = false,
  className = '',
  accountList = {
    data: [],
    loading: false
  },
  cameraGroupTypes
}: Props<T, K>) => {
  const { t } = useTranslation();

  const filterOption = (input: string, option?: { label: string; value: string | number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className={`${styles.container} ${className}`}>
      <Form.Item<TCameraGroupGeneralFieldType>
        name="name"
        label={`${t('forms.name.label')}`}
        rules={[
          {
            required: true,
            message: t('forms.name.required')
          },
          {
            validator: (_, value: string) =>
              ValidationUtilities.deviceGroupNameValidationFn({
                value,
                errorMessage: t('forms.cameraGroupName.pattern')
              })
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item<TCameraGroupGeneralFieldType>
        name="accountId"
        label={`${t('forms.accountId.label')}`}
        rules={[{ required: !editMode, message: t('forms.accountId.required') }]}
      >
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          disabled={editMode}
          suffixIcon={accountList.loading ? undefined : <DropdownIcon color="var(--primary-1)" />}
          options={orderBy(
            accountList.data.map(
              (item: K): TSelect => ({
                label: item.name,
                value: item.id
              })
            ),
            'label',
            ['asc']
          )}
          loading={accountList.loading}
        />
      </Form.Item>
      <Form.Item<TCameraGroupGeneralFieldType>
        name="cameraGroupTypeId"
        label={`${t('forms.cameraGroupType.label')}`}
        rules={[{ required: true, message: t('forms.cameraGroupType.required') }]}
      >
        <Select
          suffixIcon={
            cameraGroupTypes?.loading ? undefined : <DropdownIcon color="var(--primary-1)" />
          }
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          options={cameraGroupTypes?.data.map((value: T) => ({
            label: value?.name,
            value: value?.id
          }))}
          loading={cameraGroupTypes?.loading}
        />
      </Form.Item>
      <Form.Item<TCameraGroupGeneralFieldType>
        name="comment"
        label={t('forms.comment.label')}
        rules={[
          {
            validator: (_, value: string) =>
              ValidationUtilities.commentValidationFn({
                value,
                errorMessage: t('forms.comment.maxLength')
              })
          }
        ]}
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
      </Form.Item>
      <div className="border-top pt-4 mt-4">
        <Form.Item<TCameraGroupGeneralFieldType>
          name="address"
          label={`${t('forms.address.label')}`}
          rules={[
            {
              validator: (_, value: string) =>
                ValidationUtilities.addressValidationFn({
                  value,
                  errorMessage: t('forms.address.maxLength')
                })
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={`${t('forms.latLon.label')}`} className="mb-0">
          <div className={`d-flex flex-column flex-md-row gap-0 gap-md-2 ${styles.inputGroups}`}>
            <Form.Item<TCameraGroupGeneralFieldType>
              name="latitude"
              className="flex-fill"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.latitudeValidationFn({
                      value,
                      errorMessage: t('forms.latLon.latValidation')
                    })
                }
              ]}
            >
              <Input className={`${styles.addonData} text-truncate`} addonBefore="Latitude" />
            </Form.Item>
            <Form.Item<TCameraGroupGeneralFieldType>
              name="longitude"
              className="flex-fill"
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.longitudeValidationFn({
                      value,
                      errorMessage: t('forms.latLon.lonValidation')
                    })
                }
              ]}
            >
              <Input className={`${styles.addonData} text-truncate`} addonBefore="Longitude" />
            </Form.Item>
          </div>
        </Form.Item>
      </div>
    </div>
  );
};

export default React.memo(General) as typeof General;
