import { Button, ButtonProps } from 'antd';
import React, { ComponentProps, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionWrapper } from '../PermissionWrap/PermissionWrap';
import { TFunction } from 'i18next';

type Props = {
  onSubmit?: () => void;
  onCancel?: () => void;
  isAllowSubmit?: boolean;
  CancelTypographyProps?: ButtonProps;
  SubmitTypographyProps?: ButtonProps;
  SubmitButtonPermission?: Omit<ComponentProps<typeof PermissionWrapper>, 'children'>;
};

const ButtonCancel = ({
  CancelTypographyProps,
  onCancel,
  t
}: Pick<Props, 'CancelTypographyProps' | 'onCancel'> & { t: TFunction<'en', undefined> }) => {
  return (
    <Button
      {...CancelTypographyProps}
      className="btn-action btn-outline me-3"
      onClick={() => {
        onCancel && onCancel();
      }}
      disabled={false}
    >
      {t('components.cancel')}
    </Button>
  );
};

const ButtonSubmit = ({
  SubmitTypographyProps,
  onSubmit,
  t
}: Pick<Props, 'SubmitTypographyProps' | 'onSubmit'> & { t: TFunction<'en', undefined> }) => {
  return (
    <Button
      {...SubmitTypographyProps}
      className="btn-action btn-fill"
      onClick={() => {
        onSubmit && onSubmit();
      }}
    >
      {t('components.submit')}
    </Button>
  );
};

const FormAction: FC<Props> = memo(
  ({
    onCancel,
    onSubmit,
    isAllowSubmit = true,
    SubmitTypographyProps = {},
    CancelTypographyProps = {},
    SubmitButtonPermission = {}
  }) => {
    const { t } = useTranslation();

    return (
      <div className="d-flex justify-content-end pt-2 pb-4">
        <ButtonCancel t={t} CancelTypographyProps={CancelTypographyProps} onCancel={onCancel} />

        {isAllowSubmit &&
          (Object.keys(SubmitButtonPermission).length > 0 ? (
            <PermissionWrapper {...SubmitButtonPermission}>
              <ButtonSubmit
                t={t}
                SubmitTypographyProps={SubmitTypographyProps}
                onSubmit={onSubmit}
              />
            </PermissionWrapper>
          ) : (
            <ButtonSubmit t={t} SubmitTypographyProps={SubmitTypographyProps} onSubmit={onSubmit} />
          ))}
      </div>
    );
  }
);

export { FormAction };
