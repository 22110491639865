import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import styles from './devicePage.module.scss';
import { useEOSSContext } from 'context/EOSSContext';
import { useEffect, useRef, useState } from 'react';
import { Routes } from 'enums';
import { TDeviceType } from 'models';
import { Tooltip } from 'antd';
import useResponsive from 'hooks/useResponsive';
import useFlag from 'hooks/useFlag';

function DevicesPageView() {
  const [currentDeviceType, setCurrentDeviceType] = useState<TDeviceType | null>(null);
  const { listDeviceType, refetchListDeviceType } = useEOSSContext();
  const params = useParams<{ deviceType: string }>();
  const navigate = useNavigate();
  const refsMenu = useRef<Array<HTMLDivElement | null>>([]);
  const { isMobile } = useResponsive();

  const [leftColumnOpen, markAsLeftColumnOpen, markAsLeftColumnClose] = useFlag(true);

  useEffect(() => {
    if (!params.deviceType && listDeviceType.length > 0) {
      navigate(`${(listDeviceType[0].name ?? '').toLowerCase()}`);
    }
  }, [params, listDeviceType]);

  useEffect(() => {
    refetchListDeviceType();
  }, []);

  useEffect(() => {
    if (currentDeviceType && refsMenu) {
      refsMenu.current[currentDeviceType.id]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      markAsLeftColumnOpen();
    }
  }, [refsMenu, currentDeviceType]);

  return (
    <div className={`h-100 d-flex flex-column ${styles.container}`}>
      <div className={styles.ctnTab}>
        {listDeviceType.map((deviceType, index) => {
          const findItem = listDeviceType.findIndex((val) => val.id === currentDeviceType?.id);
          const nextItem = findItem + 1;
          const prevItem = findItem - 1;
          return (
            <div
              ref={(element) => {
                refsMenu.current[deviceType.id] = element;
              }}
              data-position={nextItem === index ? 'next' : prevItem === index ? 'prev' : ''}
              key={deviceType.id}
              className={`${styles.tab} ${
                currentDeviceType?.id === deviceType.id ? styles.active : ''
              } `}
            >
              <Link to={`${Routes.EDevicesRoutes.INDEX}/${(deviceType.name ?? '').toLowerCase()}`}>
                <Tooltip
                  trigger={isMobile ? ['click'] : ['hover']}
                  destroyTooltipOnHide
                  placement="topLeft"
                  title={deviceType.name}
                >
                  <div className={styles.tabName}>{deviceType.displayName}</div>
                </Tooltip>
              </Link>
            </div>
          );
        })}
      </div>
      <Outlet
        context={{
          onSetCurrentDeviceType: (deviceType: TDeviceType | null) =>
            setCurrentDeviceType(deviceType),
          leftColumnOpen,
          markAsLeftColumnOpen,
          markAsLeftColumnClose
        }}
      />
    </div>
  );
}

export default DevicesPageView;
