import { Drawer } from 'antd';
import useResponsive from 'hooks/useResponsive';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TbLayoutSidebarLeftExpandFilled } from 'react-icons/tb';
import useFlag from 'hooks/useFlag';

type Props = {
  withCollapse?: boolean; // TODO: Remove this props when support left column collapse in all pages
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  leftColumnOpen?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseMove?: () => void;
};

const LeftColumn: FC<Props> = ({
  className = '',
  withCollapse = false,
  style,
  children,
  leftColumnOpen = true,
  onMouseEnter,
  onMouseLeave,
  onMouseMove
}) => {
  const { isDesktop, isSmallDesktop } = useResponsive();
  const [isOpen, markAsOpen, markAsClose] = useFlag(false);
  const location = useLocation();

  useEffect(() => {
    if (!isDesktop) {
      markAsClose();
    }
  }, [isDesktop]);

  useEffect(() => {
    markAsClose();
  }, [location.pathname, location.search]);

  useEffect(() => {
    function closeListener() {
      markAsClose();
    }

    function openListener() {
      markAsOpen();
    }

    document.addEventListener('close-sidebar-action', closeListener);
    document.addEventListener('open-sidebar-action', openListener);

    return () => {
      document.removeEventListener('close-sidebar-action', closeListener);
      document.removeEventListener('open-sidebar-action', openListener);
    };
  }, []);

  if (!isDesktop) {
    return (
      <div>
        <Drawer
          classNames={{ body: 'p-3 pe-1', header: 'py-2' }}
          destroyOnClose
          closeIcon={<TbLayoutSidebarLeftExpandFilled color="white" size={24} />}
          open={isOpen}
          width={'85%'}
          forceRender
          styles={{
            header: {
              backgroundColor: 'var(--primary-2)'
            }
          }}
          onClose={() => markAsClose()}
        >
          {children}
        </Drawer>
      </div>
    );
  }

  if (!withCollapse) {
    return <>{children}</>;
  }

  return (
    <div
      className={`position-absolute h-100 overflow-x-hidden overflow-y-auto custom-scrollbar ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      style={{
        ...style,
        width: isSmallDesktop ? '25%' : isDesktop ? '20%' : 0,
        zIndex: leftColumnOpen && isDesktop ? 1 : -1,
        transition: leftColumnOpen ? 'z-index 3s ease, width 0.45s ease' : 'z-index 0.2s ease'
      }}
    >
      <div
        style={{
          paddingRight: 10
        }}
      >
        {children}
      </div>
    </div>
  );
};

export { LeftColumn };
