import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EStorageKey, Routes } from 'enums';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './tableCameraGroup.module.scss';
import { concatCameraNamePrefix } from 'utils/common';

type Props = {
  loading: boolean;
  disabled?: boolean | undefined;
  serialNumber: string;
  listCameraGroup: { id: number; name: string }[];
  camerasSelectedRow: React.Key[];
  isThermalOpticalCamera?: boolean;
  onUnassignCameraGroup: (keys: number[]) => void;
  onCamerasGroupBindingSelectedChange: (keys: React.Key[]) => void;
};

function TableCameraGroup({
  listCameraGroup,
  serialNumber,
  loading,
  isThermalOpticalCamera = false,
  camerasSelectedRow,
  onUnassignCameraGroup,
  onCamerasGroupBindingSelectedChange,
  disabled
}: Props) {
  const { t } = useTranslation();

  function checkGroupIsNew(id: number): boolean {
    const getItem = sessionStorage.getItem(EStorageKey.DEVICE_GROUPS_BINDING_KEYS);
    const listKeys: Array<number> = getItem ? JSON.parse(getItem) : [];
    if (listKeys.length > 0) {
      return Boolean(listKeys.find((key) => key === id));
    }
    return false;
  }

  const columns: ColumnsType<{ id: number; name: string }> = useMemo(
    () => [
      {
        title: t('devicePage.table.columns.groupName'),
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => (
          <Link
            className="text-nowrap text-truncate"
            to={`${Routes.ECameraGroupRoutes.LISTING}/${record.id}?search=${value}`}
          >
            {value}
          </Link>
        )
      }
    ],
    [isThermalOpticalCamera]
  );

  const cameraNameColumn = useMemo(
    () => [
      {
        title: t('devicePage.table.columns.cameraName'),
        dataIndex: 'cameraNames',
        key: 'cameraNames',
        render: (value: string[]) => {
          if (value && value.length > 0) {
            const opticalCamera = value[0] ?? '';
            const thermalCamera = value[1] ?? '';

            return (
              <div>
                <div>
                  {concatCameraNamePrefix({
                    serialNumber: serialNumber,
                    cameraName: opticalCamera,
                    isDualSensor: true,
                    isOpticalCamera: true
                  })}
                  {','}
                </div>
                <div>
                  {concatCameraNamePrefix({
                    serialNumber: serialNumber,
                    cameraName: thermalCamera,
                    isDualSensor: true,
                    isOpticalCamera: false
                  })}
                </div>
              </div>
            );
          }
          return <></>;
        }
      }
    ],
    []
  );

  useEffect(() => {
    const findRow = document.querySelector('.new-group');
    if (findRow) {
      findRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    onCamerasGroupBindingSelectedChange(listCameraGroup.map((cameraGroup) => cameraGroup.id));
  }, [listCameraGroup]);

  return (
    <Table
      id="table-device-group-binding-device-page"
      className={styles.container}
      loading={loading}
      tableLayout="auto"
      dataSource={listCameraGroup}
      columns={[...columns, ...(isThermalOpticalCamera ? cameraNameColumn : [])]}
      rowClassName={({ id }, index) =>
        `${index % 2 ? 'row-even' : 'row-odd'} ${checkGroupIsNew(id) ? 'new-group' : ''}`
      }
      scroll={
        listCameraGroup.length > 0
          ? {
              x: 'auto'
            }
          : {}
      }
      rowKey="id"
      rowSelection={{
        columnTitle: 'Bound',
        type: 'checkbox',
        hideSelectAll: true,
        columnWidth: 70,
        selectedRowKeys: camerasSelectedRow,
        getCheckboxProps: () => ({
          disabled: disabled
        }),
        onChange: (selectedRowKeys) => {
          onCamerasGroupBindingSelectedChange(selectedRowKeys);
          onUnassignCameraGroup(
            listCameraGroup
              .filter((value) => !selectedRowKeys.includes(value.id))
              .map((val) => val.id)
          );
        }
      }}
      pagination={false}
    />
  );
}

export default TableCameraGroup;
