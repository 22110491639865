import { useForm } from 'antd/es/form/Form';
import { DEFAULT_PAGE_NUMBER } from 'constant';
import { useLoaderContext } from 'context/LoaderContext';
import { useAppUtil } from 'context/UtilContext';
import { EHttpStatusCode, EStorageKey, Routes } from 'enums';
import type { TAccount, TBilling, TContact, TDomain, TPaginationEOSS } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AccountRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';

export type TAccountFieldType = {
  accountNumber: string;
  name: string;
  domainId: number;
  accountTypeId: number;
  comment?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  url?: string;
  contacts?: TContact[];
  billing?: TBilling;
  featureFlags: {
    astrolytiks: boolean;
    ups: boolean;
    thermal: boolean;
  };
  invalidId: number;
};

export type TListState<T> = {
  data: T[];
  loading: boolean;
  error: string;
  page?: TPaginationEOSS;
};

export type TSelect = {
  label: string;
  value: number | string;
};

export const useAccountPageController = () => {
  const navigate = useNavigate();
  const { loader } = useLoaderContext();
  const { openNotification } = useAppUtil();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get('search');
  const { t } = useTranslation();

  const [searchForm] = useForm();
  const [serviceDeviceForm] = useForm();
  const [referenceDeviceForm] = useForm();

  const accountRepository = AccountRepository(AxiosClient);
  const [account, setAccount] = useState<TAccount>();
  const refetchCountRef = useRef<number>(0);

  const [actionStatus, setActionStatus] = useState<{
    type: 'assign' | null;
    success: boolean;
  }>({
    type: 'assign',
    success: false
  });

  const { accountId } = useParams();

  const handleSearchChange = (value: string) => {
    if (value === '') {
      setSearchParams();
    }
  };

  const handleSearchClick = (value: string) => {
    if (value !== '') {
      searchParams.set('search', value);
      searchParams.set('page', DEFAULT_PAGE_NUMBER.toString());

      if (!accountId && value === searchText) {
        refetchCountRef.current += 1;
      } else {
        refetchCountRef.current = 0;
      }
    }
    navigate({ pathname: Routes.EAccountRoutes.LISTING, search: searchParams.toString() });
  };

  const handleAssignClick = async (serviceDeviceSerial: string, callback: () => void) => {
    if (!accountId || Number.isNaN(Number(accountId))) return;
    try {
      const code = await accountRepository.assignDeviceToAccount({
        accountId: accountId,
        serviceDeviceSerial
      });
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.assign')} ${t('devicePage.entity')}`,
          description: `${t('components.success')}`,
          onClose: () => {
            sessionStorage.removeItem(EStorageKey.SERVICE_GROUPS_BINDING_KEYS);
          }
        });
        sessionStorage.setItem(
          EStorageKey.SERVICE_GROUPS_BINDING_KEYS,
          JSON.stringify([serviceDeviceSerial])
        );

        setActionStatus({
          type: 'assign',
          success: true
        });
        serviceDeviceForm.resetFields();
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        actionStr: t('actions.assign'),
        action: 'other',
        entity: t('devicePage.entity'),
        t
      });

      openNotification({
        type: 'error',
        title: `${t('actions.assign')} ${t('devicePage.entity')}`,
        description: message
      });
      setActionStatus({
        type: 'assign',
        success: false
      });
    } finally {
      callback();
    }
  };

  const handleResetActionStatus = () =>
    setActionStatus({
      type: null,
      success: false
    });

  const handleChangeAccount = (value?: TAccount) => setAccount(value);

  const handleClearForm = () => serviceDeviceForm.resetFields();

  const handleFetchDomainList = async (): Promise<{
    success: boolean;
    data: TDomain[];
    page?: TPaginationEOSS;
    errorMsg: string;
  }> => {
    try {
      loader.start();
      const { data, code } = await accountRepository.getAllDomains();
      if (code === EHttpStatusCode.OK) {
        return { success: true, data, errorMsg: '' };
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('domainPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('domainPage.entity')}`,
        description: message
      });
      return { success: false, data: [], errorMsg: '' };
    } finally {
      loader.complete();
    }
    return { success: true, data: [], errorMsg: '' };
  };

  return {
    searchForm,
    serviceDeviceForm,
    referenceDeviceForm,
    actionStatus,
    account,
    refetchCountRef,
    handleChangeAccount,
    handleClearForm,
    onSearchChange: handleSearchChange,
    onSearch: handleSearchClick,
    onAssign: handleAssignClick,
    onFetchDomain: handleFetchDomainList,
    onResetActionStatus: handleResetActionStatus
  };
};
