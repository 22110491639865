import { Form } from 'antd';
import type { SegmentedValue } from 'antd/es/segmented';
import { DEFAULT_PAGE_NUMBER } from 'constant';
import { useAppUtil } from 'context/UtilContext';
import {
  ECameraBindingSwitchOption,
  EDeviceType,
  EHttpStatusCode,
  EThermalCameraType,
  Routes
} from 'enums';
import usePrevious from 'hooks/usePrevious';
import { isEqual, orderBy } from 'lodash';
import type {
  DataList,
  TCameraBinding,
  TCameraGroup,
  TCameraGroupType,
  TEyeviewUserAssign,
  TOtherDevice
} from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import type { TCameraGroupGeneralFieldType } from 'presentation/pages/CameraGroupsPage/cameraGroupPage.types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useOutletContext,
  useParams,
  useRouteLoaderData,
  useSearchParams
} from 'react-router-dom';
import { CameraGroupRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import {
  checkDeviceStatus,
  concatCameraNamePrefix,
  handleApiError,
  isThermalCameraInThermalOpticalDevice,
  parseENotationNumberToString
} from 'utils/common';

type TAction = 'sync' | 'bind' | 'assign';

enum ESortingDevicePage {
  NO_SORT = 0,
  ORDER_BY_CAMERA_NAME = 1,
  ORDER_BY_SERIAL_NUMBER = 2
}

export const useCameraGroupInformationPageController = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formEditCameraGroup] = Form.useForm();

  const { openNotification } = useAppUtil();

  const cameraGroupRepository = CameraGroupRepository(AxiosClient);

  const { cameraGroupId } = useParams<{ cameraGroupId: string }>();

  const { actionStatus, onChangeCurrentCamera, onResetActionStatus, resetBindForm } =
    useOutletContext<{
      actionStatus: {
        type: TAction | null;
        success: boolean;
      };
      onChangeCurrentCamera: (value: TCameraGroup) => void;
      onResetActionStatus: () => void;
      resetBindForm: () => void;
    }>();

  const currentCameraGroupLoader = useRouteLoaderData(
    Routes.ECameraGroupRoutes.DETAILED
  ) as TCameraGroup;

  const [searchParams, setSearchParams] = useSearchParams();

  const [userAssignmentList, setUserAssignmentList] = useState<DataList<TEyeviewUserAssign[]>>({
    data: [],
    loading: false
  });
  const [listCameraBinding, setListCameraBinding] = useState<
    DataList<Array<TCameraBinding & { key: string }>>
  >({
    data: [],
    loading: false
  });
  const [cameraGroupTypes, setCameraGroupTypes] = useState<DataList<TCameraGroupType[]>>({
    data: [],
    loading: false
  });
  const [astroDeviceList, setAstroDeviceList] = useState<DataList<TOtherDevice[]>>({
    data: [],
    loading: false
  });
  const [upsMonitorList, setUpsMonitorList] = useState<DataList<TOtherDevice[]>>({
    data: [],
    loading: false
  });

  const [orderSelectValue, setOrderSelectValue] = useState<{
    orderKey: ESortingDevicePage;
    shouldUpdate: boolean;
  }>({
    orderKey: ESortingDevicePage.NO_SORT,
    shouldUpdate: true
  });

  const previousOrderSelectValue = useRef<ESortingDevicePage>(ESortingDevicePage.NO_SORT);

  const [cameraGroupSelectedRow, setCameraGroupSelectedRow] = useState<React.Key[]>([]);
  const [eyeviewUserSelectedRow, setEyeviewUserSelectedRow] = useState<React.Key[]>([]);
  const [astroDeviceSelectedRow, setAstroDeviceSelectedRow] = useState<React.Key[]>([]);
  const [upsMonitorSelectedRow, setUpsMonitorSelectedRow] = useState<React.Key[]>([]);

  const [cameraBindingSwitchValue, setCameraBindingSwitchValue] =
    useState<ECameraBindingSwitchOption>(ECameraBindingSwitchOption.Device);

  const prevBindingSwitchValue = usePrevious(cameraBindingSwitchValue);

  const [currentCameraGroup, setCurrentCameraGroup] =
    useState<TCameraGroup>(currentCameraGroupLoader);

  const isAllPublicIPMatches: boolean = useMemo(
    () =>
      Array.from(
        new Set([
          ...currentCameraGroup.cameras.reduce(
            (accumulator: string[], currentValue: TCameraBinding) => {
              if (checkDeviceStatus(currentValue.lastContactAt) && currentValue.publicIp) {
                accumulator.push(currentValue.publicIp);
              }
              return accumulator;
            },
            []
          ),
          ...currentCameraGroup.devices.reduce(
            (accumulator: string[], currentValue: TOtherDevice) => {
              if (checkDeviceStatus(currentValue.lastContactAt) && currentValue.publicIp) {
                accumulator.push(currentValue.publicIp);
              }
              return accumulator;
            },
            []
          )
        ])
      ).length <= 1,
    [currentCameraGroup]
  );

  const deviceBindingExtraOptions = useMemo(
    () => [ECameraBindingSwitchOption.Device, ECameraBindingSwitchOption.Camera],
    []
  );

  const menuItems = useMemo(
    () =>
      [
        {
          label: '-',
          value: ESortingDevicePage.NO_SORT,
          visible: true
        },
        {
          label: t('cameraGroupPage.cameraBindingTable.cameraName'),
          value: ESortingDevicePage.ORDER_BY_CAMERA_NAME,
          visible: cameraBindingSwitchValue === ECameraBindingSwitchOption.Camera
        },
        {
          label: t('cameraGroupPage.cameraBindingTable.serialNumber'),
          value: ESortingDevicePage.ORDER_BY_SERIAL_NUMBER,
          visible: true
        }
      ].filter((value) => value.visible),
    [cameraBindingSwitchValue, t]
  );

  function areListsInOrder(list1: string[], list2: string[]): boolean {
    if (list1.length !== list2.length) {
      return false; // Lists have different lengths
    }

    // Check if ids are in the same order
    for (let i = 0; i < list1.length; i++) {
      if (list1[i] !== list2[i]) {
        return false;
      }
    }

    return true; // All ids match in order
  }

  // Do not show the refresh button unless there cameras has been a change to the order.
  const isShowRefreshButton: boolean = useMemo(() => {
    const isNotSameOrder = !areListsInOrder(
      currentCameraGroup.cameras
        .filter(
          (value: TCameraBinding) =>
            !isThermalCameraInThermalOpticalDevice({
              cameraType: value.cameraType,
              deviceTypeName: value.deviceTypeName
            })
        )
        .map((value: TCameraBinding) => value.serialNumber),
      listCameraBinding.data
        .filter(
          (value: TCameraBinding & { key: string }) =>
            !isThermalCameraInThermalOpticalDevice({
              deviceTypeName: value.deviceTypeName,
              cameraType: value.cameraType
            })
        )
        .map((value: TCameraBinding) => value.serialNumber)
    );

    // Compare current cameras checked list with default cameras checked list
    const checkedSerial = Array.from(
      new Set(currentCameraGroup.cameras.map((value: TCameraBinding) => value.serialNumber))
    );
    const defaultCheck = checkedSerial
      .reduce((acc: string[], current: string) => {
        // Find camera with serial number which is not thermal camera in thermal optical device
        const currentCameraIdIndex = currentCameraGroup.cameras.findIndex(
          (value: TCameraBinding) =>
            value.serialNumber === current &&
            !isThermalCameraInThermalOpticalDevice({
              cameraType: value.cameraType,
              deviceTypeName: value.deviceTypeName
            })
        );
        if (currentCameraIdIndex === -1) {
          return acc;
        }
        const currentIds = [...acc];
        currentIds.push(`${currentCameraGroup.cameras[currentCameraIdIndex].id}`);
        return currentIds;
      }, [])
      .sort((a: string, b: string) => (Number(a) > Number(b) ? -1 : 1));

    // Sort current selected ids
    const cameraGroupSelectedRowSorted = cameraGroupSelectedRow
      .sort((a: React.Key, b: React.Key) => (Number(a.toString()) > Number(b.toString()) ? -1 : 1))
      .map((value) => value.toString());

    const isNotSameChecked = !areListsInOrder(defaultCheck, cameraGroupSelectedRowSorted);

    return isNotSameOrder || isNotSameChecked;
  }, [currentCameraGroup, listCameraBinding, cameraGroupSelectedRow]);

  const handleCancel = useCallback(() => {
    searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
    setSearchParams(searchParams);
    navigate(
      {
        pathname: Routes.ECameraGroupRoutes.LISTING,
        search: searchParams.toString()
      },
      { replace: true }
    );
  }, []);

  const handleRefetchCameraGroupBinding = async () => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    try {
      setListCameraBinding((prev) => ({
        ...prev,
        loading: true
      }));
      const { data, code } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        setCurrentCameraGroup((prev) => ({
          ...prev,
          cameras: data.cameras
        }));
        onChangeCurrentCamera(data);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: `${cameraGroupId}`,
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.deviceBind.title')}`,
        description: message
      });
    } finally {
      setListCameraBinding((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleRefetchEyeviewUserAssignment = async () => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    try {
      setUserAssignmentList((prev) => ({
        ...prev,
        loading: true
      }));
      const { data, code } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        setCurrentCameraGroup((prev) => ({
          ...prev,
          users: data.users
        }));
        onChangeCurrentCamera(data);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: `${cameraGroupId}`,
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.eyeviewUser.title')}`,
        description: message
      });
    } finally {
      setUserAssignmentList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleFetchCameraGroupType = async () => {
    if (cameraGroupTypes.loading) return;
    try {
      const { data, code } = await cameraGroupRepository.getListCameraGroupType();
      if (code === EHttpStatusCode.OK) {
        setCameraGroupTypes({
          data,
          loading: false
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupType.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupType.entity')}`,
        description: message
      });
    } finally {
      setCameraGroupTypes((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleCameraBindingRefresh = async () => {
    if (listCameraBinding.loading) return;
    const cameraGroupIdNumber = Number(cameraGroupId);
    try {
      setListCameraBinding({
        data: listCameraBinding.data,
        loading: true
      });
      const {
        data: { cameras },
        code
      } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        previousOrderSelectValue.current = ESortingDevicePage.NO_SORT;
        setOrderSelectValue({
          orderKey: ESortingDevicePage.NO_SORT,
          shouldUpdate: false
        });
        setCurrentCameraGroup((prev) => ({
          ...prev,
          cameras
        }));
        setListCameraBinding({
          data: cameras.map((item: TCameraBinding) => ({
            ...item,
            key: item.id.toString()
          })),
          loading: false
        });
        setCameraGroupSelectedRow(
          cameras
            .filter(
              (value: TCameraBinding) =>
                !(
                  (value.deviceTypeName ?? '').toUpperCase() ===
                    EDeviceType.THERMAL_OPTICAL_CAMERA &&
                  (value.cameraType ?? '').toLowerCase() === EThermalCameraType.THERMAL
                )
            )
            .map((value) => value.id.toString())
        );
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: `${cameraGroupId}`,
        t
      });
      setListCameraBinding({
        data: listCameraBinding.data,
        loading: false
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.deviceBind.title')}`,
        description: message
      });
    }
  };

  const handleUpsMonitorRefresh = async () => {
    if (upsMonitorList.loading) return;
    try {
      setUpsMonitorList((prev) => ({
        ...prev,
        loading: true
      }));
      const cameraGroupIdNumber = Number(cameraGroupId);
      const {
        data: { devices },
        code
      } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);

      if (code === EHttpStatusCode.OK) {
        const UPSList = devices.reduce((acc: TOtherDevice[], item: TOtherDevice) => {
          if (item.typeName === EDeviceType.UPS_MONITOR) {
            acc.push(item);
          }
          return acc;
        }, []);
        setUpsMonitorList({
          data: UPSList,
          loading: false
        });
        setUpsMonitorSelectedRow(UPSList.map((camera: TOtherDevice) => camera.serialNumber));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: `${cameraGroupId}`,
        t
      });
      setUpsMonitorList({
        data: [],
        loading: false
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.ups.title')}`,
        description: message
      });
    }
  };

  const handleAstroDeviceRefresh = async () => {
    if (astroDeviceList.loading) return;
    const cameraGroupIdNumber = Number(cameraGroupId);
    try {
      setAstroDeviceList((prev) => ({
        ...prev,
        loading: true
      }));
      const {
        data: { devices },
        code
      } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        const astroList = devices.reduce((acc: TOtherDevice[], item: TOtherDevice) => {
          if (item.typeName === EDeviceType.ASTRO_CAMERA) {
            acc.push(item);
          }
          return acc;
        }, []);
        setAstroDeviceList({
          data: astroList,
          loading: false
        });
        setAstroDeviceSelectedRow(astroList.map((camera: TOtherDevice) => camera.serialNumber));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: `${cameraGroupId}`,
        t
      });
      setAstroDeviceList({
        data: [],
        loading: false
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.astroDevice.title')}`,
        description: message
      });
    }
  };

  const handleAstroDeviceSelectedRowChange = useCallback(
    (value: React.Key[]) => setAstroDeviceSelectedRow(value),
    []
  );

  const handleUpsMonitorSelectedRowChange = useCallback(
    (value: React.Key[]) => setUpsMonitorSelectedRow(value),
    []
  );

  const handleUpdateCameraGroup = async (
    values: TCameraGroupGeneralFieldType
  ): Promise<EHttpStatusCode> => {
    if (!cameraGroupId) return EHttpStatusCode.NOT_FOUND;
    try {
      const oldData = {
        cameraGroupId: Number(cameraGroupId),
        name: currentCameraGroup.name,
        cameraGroupTypeId: currentCameraGroup.type.id,
        description: currentCameraGroup.comment,
        latitude: currentCameraGroup.lat,
        longitude: currentCameraGroup.lon,
        address: currentCameraGroup.address
      };
      const newData = {
        cameraGroupId: Number(cameraGroupId),
        name: values.name.trim(),
        cameraGroupTypeId: values.cameraGroupTypeId,
        description: values.comment ? values.comment.trim() : '',
        address: values.address ? values.address.trim() : undefined,
        latitude: values.latitude ? Number(values.latitude) : undefined,
        longitude: values.longitude ? Number(values.longitude) : undefined
      };

      if (isEqual(oldData, newData)) {
        return EHttpStatusCode.NOT_MODIFIED;
      }
      const code = await cameraGroupRepository.updateCameraById(newData);
      return code;
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'update',
        entity: t('cameraGroupPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
      return EHttpStatusCode.BAD_REQUEST;
    }
  };

  const handleUnbindCameraFromCameraGroup = async (): Promise<EHttpStatusCode> => {
    if (!cameraGroupId) return EHttpStatusCode.NOT_FOUND;
    try {
      const oldData = currentCameraGroup.cameras
        .filter(
          (value: TCameraBinding) =>
            !isThermalCameraInThermalOpticalDevice({
              cameraType: value.cameraType,
              deviceTypeName: value.deviceTypeName
            })
        )
        .map((value: TCameraBinding) => value.serialNumber.toString());
      const cameraGroupSelectedRowSet = Array.from(new Set(cameraGroupSelectedRow));
      const data = listCameraBinding.data
        .filter(
          (value: TCameraBinding & { key: string }) =>
            !isThermalCameraInThermalOpticalDevice({
              cameraType: value.cameraType,
              deviceTypeName: value.deviceTypeName
            })
        )
        .reduce((acc: string[], current: TCameraBinding & { key: string }) => {
          const isExist = cameraGroupSelectedRowSet.find(
            (key: React.Key) => (key ?? '').toString() === current.key
          );
          if (isExist) {
            acc.push(current.serialNumber.toString());
          }
          return acc;
        }, []);
      if (isEqual(oldData, data)) {
        return EHttpStatusCode.NOT_MODIFIED;
      }
      const code = await cameraGroupRepository.unbindCamerasFromCameraGroup({
        cameraSerials: data.toString(),
        groupsId: Number(cameraGroupId)
      });
      return code;
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('camera.entity'),
        actionStr: t('actions.unassign'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.unassign')} ${t('camera.entity')}`,
        description: message
      });
      return EHttpStatusCode.BAD_REQUEST;
    }
  };

  const handleUnassignedEyeviewUser = async () => {
    if (!eyeviewUserSelectedRow) return EHttpStatusCode.NOT_FOUND;
    try {
      const oldData = currentCameraGroup.users.map((value: TEyeviewUserAssign) =>
        value.id.toString()
      );
      const newData = eyeviewUserSelectedRow.map((value: React.Key) => value.toString());
      if (isEqual(oldData, newData)) {
        return EHttpStatusCode.NOT_MODIFIED;
      }
      const code = await cameraGroupRepository.unassignedUsersFromCameraGroup({
        cameraGroupId: currentCameraGroup.id,
        userIds: newData
      });
      return code;
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('userPage.entity'),
        actionStr: t('actions.unassign'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.unassign')} ${t('userPage.entity')}`,
        description: message
      });
      return EHttpStatusCode.BAD_REQUEST;
    }
  };

  const handleFormSubmit = async (values: TCameraGroupGeneralFieldType) => {
    const allSettledResult = await Promise.allSettled([
      handleUnbindCameraFromCameraGroup(),
      handleUnassignedEyeviewUser(),
      handleUpdateCameraGroup(values)
    ]);

    const isSuccessfully = allSettledResult.some(
      (result) => result.status === 'fulfilled' && result.value === EHttpStatusCode.OK
    );
    const hasFailed = allSettledResult.some(
      (result) =>
        result.status === 'fulfilled' &&
        [EHttpStatusCode.NOT_FOUND, EHttpStatusCode.BAD_REQUEST].includes(result.value)
    );
    const isUnchanged = allSettledResult.every(
      (result) => result.status === 'fulfilled' && result.value === EHttpStatusCode.NOT_MODIFIED
    );

    if (isSuccessfully || isUnchanged) {
      openNotification({
        type: 'success',
        title: `${t('actions.update')} ${t('cameraGroupPage.entity')}`,
        description: `${t('components.success')}`
      });
    }

    if (isUnchanged || (isSuccessfully && !hasFailed)) {
      searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
      setSearchParams(searchParams);

      navigate({
        pathname: Routes.ECameraGroupRoutes.LISTING,
        search: searchParams.toString()
      });
    }
  };

  const handleCameraGroupSelectedRowChange = useCallback(
    (value: React.Key[]) => setCameraGroupSelectedRow(value),
    []
  );

  const handleCameraBindingListChange = useCallback(
    (value: { data: Array<TCameraBinding & { key: string }>; loading: boolean }) =>
      setListCameraBinding(value),
    []
  );

  const handleCameraBindingSwitchChange = useCallback((value: SegmentedValue) => {
    setCameraBindingSwitchValue(
      ECameraBindingSwitchOption.Device === value
        ? ECameraBindingSwitchOption.Device
        : ECameraBindingSwitchOption.Camera
    );
  }, []);

  const handleEyeviewUserSelectedRowChange = useCallback(
    (value: React.Key[]) => setEyeviewUserSelectedRow(value),
    []
  );

  useEffect(() => {
    resetBindForm();
  }, []);

  useEffect(() => {
    if (currentCameraGroupLoader) {
      setCurrentCameraGroup(currentCameraGroupLoader);
    }
  }, [currentCameraGroupLoader]);

  useEffect(() => {
    if (actionStatus.success && actionStatus.type) {
      switch (actionStatus.type) {
        case 'assign': {
          handleRefetchEyeviewUserAssignment();
          break;
        }
        case 'sync':
        case 'bind': {
          handleRefetchCameraGroupBinding();
          break;
        }
      }
      setOrderSelectValue({
        orderKey: ESortingDevicePage.NO_SORT,
        shouldUpdate: false
      });
      onResetActionStatus();
    }
  }, [actionStatus]);

  useEffect(() => {
    handleFetchCameraGroupType();
  }, []);

  useEffect(() => {
    if (currentCameraGroup) {
      setListCameraBinding({
        data: currentCameraGroup.cameras.map((camera: TCameraBinding) => ({
          ...camera,
          key: camera.id.toString()
        })),
        loading: false
      });
      setCameraGroupSelectedRow(
        currentCameraGroup.cameras
          .filter(
            (value: TCameraBinding) =>
              !(
                (value.deviceTypeName ?? '').toUpperCase() === EDeviceType.THERMAL_OPTICAL_CAMERA &&
                (value.cameraType ?? '').toLowerCase() === EThermalCameraType.THERMAL
              )
          )
          .map((value) => value.id.toString())
      );

      const astroList = currentCameraGroup.devices.filter(
        (item: TOtherDevice) => item.typeName === EDeviceType.ASTRO_CAMERA
      );
      setAstroDeviceList({
        data: astroList,
        loading: false
      });
      setAstroDeviceSelectedRow(astroList.map((item: TOtherDevice) => item.serialNumber));

      const upsMonitorList = currentCameraGroup.devices.filter(
        (item: TOtherDevice) => item.typeName === EDeviceType.UPS_MONITOR
      );
      setUpsMonitorList({
        data: upsMonitorList,
        loading: false
      });
      setUpsMonitorSelectedRow(upsMonitorList.map((item: TOtherDevice) => item.serialNumber));

      const userAssignments = currentCameraGroup.users;
      setUserAssignmentList({
        data: orderBy(userAssignments, 'username', 'asc'),
        loading: false
      });
      setEyeviewUserSelectedRow(userAssignments.map((item: TEyeviewUserAssign) => item.id));
    }
  }, [currentCameraGroup]);

  useEffect(() => {
    if (currentCameraGroup && cameraGroupTypes.data.length > 0) {
      formEditCameraGroup.setFieldValue('name', currentCameraGroup?.name);
      formEditCameraGroup.setFieldValue('accountId', currentCameraGroup?.accountName);
      formEditCameraGroup.setFieldValue('cameraGroupTypeId', currentCameraGroup?.type?.id);
      formEditCameraGroup.setFieldValue('comment', currentCameraGroup?.comment);
      formEditCameraGroup.setFieldValue('address', currentCameraGroup?.address);
      formEditCameraGroup.setFieldValue(
        'latitude',
        parseENotationNumberToString(currentCameraGroup?.lat)
      );
      formEditCameraGroup.setFieldValue(
        'longitude',
        parseENotationNumberToString(currentCameraGroup?.lon)
      );
    }
  }, [currentCameraGroup, cameraGroupTypes]);

  useEffect(() => {
    if (!orderSelectValue.shouldUpdate) {
      return;
    }
    switch (orderSelectValue.orderKey) {
      case ESortingDevicePage.NO_SORT: {
        setListCameraBinding((prev) => ({
          ...prev,
          data: currentCameraGroup.cameras.map((camera: TCameraBinding) => ({
            ...camera,
            key: camera.id.toString()
          }))
        }));
        break;
      }
      case ESortingDevicePage.ORDER_BY_CAMERA_NAME: {
        setListCameraBinding((prev) => ({
          ...prev,
          data: prev.data.sort(
            (a: TCameraBinding & { key: string }, b: TCameraBinding & { key: string }) => {
              const prefixA = concatCameraNamePrefix({
                serialNumber: a?.serialNumber,
                cameraName: a?.name,
                isDualSensor: a?.deviceTypeName === EDeviceType.THERMAL_OPTICAL_CAMERA,
                isOpticalCamera: (a?.cameraType ?? '').toLowerCase() === EThermalCameraType.OPTICAL
              });
              const prefixB = concatCameraNamePrefix({
                serialNumber: b?.serialNumber,
                cameraName: b?.name,
                isDualSensor: b?.deviceTypeName === EDeviceType.THERMAL_OPTICAL_CAMERA,
                isOpticalCamera: (b?.cameraType ?? '').toLowerCase() === EThermalCameraType.OPTICAL
              });
              const aNameWithSerialPrefix = `${prefixA}${a?.name}`;
              const bNameWithSerialPrefix = `${prefixB}${b?.name}`;
              return aNameWithSerialPrefix.localeCompare(bNameWithSerialPrefix);
            }
          )
        }));
        break;
      }
      case ESortingDevicePage.ORDER_BY_SERIAL_NUMBER: {
        setListCameraBinding((prev) => ({
          ...prev,
          data: prev.data.sort(
            (a: TCameraBinding & { key: string }, b: TCameraBinding & { key: string }) =>
              a.serialNumber.localeCompare(b.serialNumber)
          )
        }));
        break;
      }
    }
  }, [orderSelectValue]);

  useEffect(() => {
    // If the current order option is camera name, switch to Device Tabs, reset the order option to no order and no update the current table camera binding list
    if (
      prevBindingSwitchValue === ECameraBindingSwitchOption.Camera &&
      cameraBindingSwitchValue === ECameraBindingSwitchOption.Device &&
      orderSelectValue.orderKey === ESortingDevicePage.ORDER_BY_CAMERA_NAME
    ) {
      setOrderSelectValue({
        orderKey: ESortingDevicePage.NO_SORT,
        shouldUpdate: false
      });
      previousOrderSelectValue.current = ESortingDevicePage.ORDER_BY_CAMERA_NAME;
    }

    if (
      prevBindingSwitchValue === ECameraBindingSwitchOption.Device &&
      cameraBindingSwitchValue === ECameraBindingSwitchOption.Camera &&
      orderSelectValue.orderKey === ESortingDevicePage.NO_SORT &&
      previousOrderSelectValue.current === ESortingDevicePage.ORDER_BY_CAMERA_NAME
    ) {
      setOrderSelectValue({
        orderKey: ESortingDevicePage.ORDER_BY_CAMERA_NAME,
        shouldUpdate: false
      });
      previousOrderSelectValue.current = ESortingDevicePage.NO_SORT;
    }
  }, [cameraBindingSwitchValue, prevBindingSwitchValue, orderSelectValue]);

  return {
    userAssignmentList,
    orderSelectValue,
    isAllPublicIPMatches,
    formEditCameraGroup,
    cameraGroupId: cameraGroupId ? parseInt(cameraGroupId) : null,
    currentCameraGroup,
    cameraGroupTypes,
    cameraBindingSwitchValue,
    deviceBindingExtraOptions,
    listCameraBinding,
    menuItems,
    /**
     * Filter out Camera with cameraType = Thermal and deviceTypeName = SIR200 from Camera Binding table.
     * Because we show only Optical Camera instead of all camera from Thermal Optical Device in Camera Binding Table.
     * Bind and UnBind now only need serial number (Thermal Camera and Optical Camera has the same serial number)
     */
    isShowRefreshButton,
    astroDeviceList,
    upsMonitorList,
    cameraGroupSelectedRow,
    astroDeviceSelectedRow,
    eyeviewUserSelectedRow,
    upsMonitorSelectedRow,
    deviceBindingSummary: {
      totalCameras: listCameraBinding.data.length,
      totalDevices: Array.from(
        new Set(
          listCameraBinding.data.map(
            (value: TCameraBinding & { key: string }) => value.serialNumber
          )
        )
      ).length
    },
    onCancel: handleCancel,
    onCameraBindingListChange: handleCameraBindingListChange,
    onCameraBindingRefresh: handleCameraBindingRefresh,
    onUpsMonitorRefresh: handleUpsMonitorRefresh,
    onCameraGroupSelectedRowChange: handleCameraGroupSelectedRowChange,
    onAstroDeviceSelectedRowChange: handleAstroDeviceSelectedRowChange,
    onUpsMonitorSelectedRowChange: handleUpsMonitorSelectedRowChange,
    onEyeviewUserSelectedRowChange: handleEyeviewUserSelectedRowChange,
    onFormSubmit: handleFormSubmit,
    onAstroDeviceRefresh: handleAstroDeviceRefresh,
    onCameraBindingSwitchChange: handleCameraBindingSwitchChange,
    onOrderTableCameraBindingChange: (value: number) => {
      setOrderSelectValue({
        orderKey: value,
        shouldUpdate: true
      });
    }
  };
};
