import { Button, Divider, Form, Input, Select, Tooltip } from 'antd';
import { DropdownIcon, MoreInfoIcon } from 'assets/icons';
import { useEOSSContext } from 'context/EOSSContext';
import { TAccount, TEyeviewUser, TValidation } from 'models';
import { FormInputTooltip, PasswordCriteriaChecking, Section } from 'presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePassword } from 'utils/common';
import styles from './userGeneral.module.scss';
import { TEyeviewUserField, TSelect } from '../EyeviewUserPageController';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import useResponsive from 'hooks/useResponsive';
import { useWatch } from 'antd/es/form/Form';
import ValidationUtilities from 'utils/validationUtils';

type Props = {
  accountList: {
    data: TAccount[];
    loading: boolean;
  };
  validationList: TValidation[];
  disableFields?: (keyof TEyeviewUser)[];
  editMode?: boolean;
  children?: React.ReactNode;
};

export default React.memo(
  ({ accountList, validationList, disableFields = [], editMode, children }: Props) => {
    const form = useFormInstance<TEyeviewUserField>();
    const { t } = useTranslation();
    const { listLanguage } = useEOSSContext();

    const { isMobile } = useResponsive();

    const passwordValue = useWatch('password', form);

    const genPassword = () => {
      const pass = generatePassword();
      form.setFieldsValue({
        password: pass,
        confirmPassword: pass
      });
    };

    const filterOption = (input: string, option?: { label: string; value: string | number }) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
      <Section
        className={styles.container}
        classNameBody="px-4 pt-3 pb-4"
        title={t('userPage.sections.general.title')}
      >
        {/**
         * CUSTOMER
         */}
        <FormInputTooltip name="accountId">
          <Form.Item<TEyeviewUserField>
            name="accountId"
            label={t('forms.accountId.label')}
            rules={[{ required: true }]}
          >
            <Select
              disabled={disableFields.includes('accountId')}
              suffixIcon={accountList.loading ? undefined : <DropdownIcon />}
              options={accountList.data.map(
                (value: TAccount): TSelect => ({
                  label: value.name,
                  value: value.id
                })
              )}
              loading={accountList.loading}
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
            />
          </Form.Item>
        </FormInputTooltip>

        {/**
         * VALID
         */}
        <Form.Item<TEyeviewUserField>
          name="invalid"
          label={t('forms.valid.label')}
          rules={[
            {
              required: true,
              message: t('forms.valid.required')
            }
          ]}
        >
          <Select
            suffixIcon={<DropdownIcon color="var(--primary-1)" />}
            options={validationList.map(
              (item: TValidation): TSelect => ({
                label: item.name,
                value: item.id
              })
            )}
            optionFilterProp="children"
            filterOption={filterOption}
          />
        </Form.Item>

        <Divider className="my-2" />

        {/**
         * USERNAME
         */}
        <FormInputTooltip name="username">
          <Form.Item<TEyeviewUserField>
            name="username"
            label={t('forms.username.label')}
            rules={[
              { required: true, message: t('forms.username.required') },
              {
                validator: (_, value: string) =>
                  ValidationUtilities.loginValidationFn({
                    value,
                    errorMessage: t('forms.login.pattern')
                  })
              }
            ]}
          >
            <Input disabled={disableFields.includes('username')} />
          </Form.Item>
        </FormInputTooltip>

        <div className="d-flex flex-column flex-sm-row gap-4">
          <div className="flex-fill">
            {/**
             * PASSWORD
             */}
            <Form.Item<TEyeviewUserField>
              name="password"
              rules={[{ required: !editMode, message: t('forms.password.required') }]}
              label={t('forms.password.label')}
            >
              <Input.Password autoComplete="new-password" tabIndex={4} />
            </Form.Item>

            {/**
             * CONFIRM PASSWORD
             */}
            <Form.Item<TEyeviewUserField>
              dependencies={['password']}
              name="confirmPassword"
              rules={[
                { required: !editMode, message: t('forms.confirmPassword.required') },
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.confirmPasswordValidationFn({
                      value,
                      editMode,
                      passwordDependency: passwordValue,
                      errorMessage: t('forms.confirmPassword.notMatching')
                    })
                }
              ]}
              label={t('forms.confirmPassword.label')}
            >
              <Input.Password tabIndex={5} />
            </Form.Item>

            <Form.Item label={isMobile ? '' : ' '} className="mt-4 mt-sm-0">
              <PasswordCriteriaChecking value={passwordValue} />
            </Form.Item>
          </div>
          {/**
           * GENERATE PASSWORD
           */}
          <Button
            className={`${styles.btnPasswordGenerate} btn-outline fw-medium`}
            onClick={genPassword}
          >
            <span>{t('userPage.component.generatePassword')}</span>
          </Button>
        </div>

        <Divider className={styles.divider} />

        <Form.Item
          className={`${styles.customRequiredStar} mb-0`}
          label={t('forms.name.label')}
          rules={[{ required: true }]}
        >
          <div className="row gx-4">
            {/**
             * FIRST NAME
             */}
            <FormInputTooltip name="firstName" className="col-12 col-slg-6 mb-2 mb-sm-0">
              <Form.Item<TEyeviewUserField>
                rules={[
                  {
                    required: true,
                    message: t('forms.firstName.required')
                  },
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.shortNameValidationFn({
                        value,
                        errorMessage: t('forms.firstName.maxLength')
                      })
                  }
                ]}
                name="firstName"
              >
                <Input addonBefore="First" className={styles.addonData} />
              </Form.Item>
            </FormInputTooltip>
            {/**
             * LAST NAME
             */}
            <FormInputTooltip name="lastName" className="col-12 col-slg-6">
              <Form.Item<TEyeviewUserField>
                rules={[
                  {
                    required: true,
                    message: t('forms.lastName.required')
                  },
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.shortNameValidationFn({
                        value,
                        errorMessage: t('forms.lastName.maxLength')
                      })
                  }
                ]}
                name="lastName"
              >
                <Input addonBefore="Last" className={styles.addonData} />
              </Form.Item>
            </FormInputTooltip>
          </div>
        </Form.Item>
        {/**
         * EMAIL
         */}
        <FormInputTooltip name="email">
          <Form.Item<TEyeviewUserField>
            label={t('forms.email.label')}
            rules={[
              {
                required: true,
                message: t('forms.email.required')
              },
              {
                validator: (_, value: string) =>
                  ValidationUtilities.emailValidationFn({
                    value,
                    errorMessages: new Map([
                      ['maxLength', t('forms.email.maxLength')],
                      ['pattern', t('forms.email.pattern')]
                    ])
                  })
              }
            ]}
            name="email"
          >
            <Input />
          </Form.Item>
        </FormInputTooltip>
        {/**
         * PHONE
         */}
        <FormInputTooltip name="phone">
          <Form.Item<TEyeviewUserField>
            label={t('forms.phone.label')}
            name="phone"
            rules={[
              {
                validator: (_, value: string) =>
                  ValidationUtilities.phoneNumberValidationFn({
                    value,
                    errorMessage: t('forms.phone.maxLength')
                  })
              }
            ]}
          >
            <Input />
          </Form.Item>
        </FormInputTooltip>
        {/**
         * ADDRESS
         */}
        <Form.Item label={t('forms.address.label')} className="mb-0">
          <div className="row gx-4">
            {/**
             * STREET
             */}
            <FormInputTooltip name="street" className="col-12 col-slg-6 mb-2 mb-sm-0">
              <Form.Item<TEyeviewUserField>
                name="street"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.streetValidationFn({
                        value,
                        errorMessage: t('forms.street.maxLength')
                      })
                  }
                ]}
              >
                <Input className={styles.addonData} addonBefore={t('forms.street.label')} />
              </Form.Item>
            </FormInputTooltip>
            {/**
             * CITY
             */}
            <FormInputTooltip name="city" className="col-12 col-slg-6 mb-2 mb-sm-0">
              <Form.Item<TEyeviewUserField>
                name="city"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.cityValidationFn({
                        value,
                        errorMessage: t('forms.city.maxLength')
                      })
                  }
                ]}
              >
                <Input className={styles.addonData} addonBefore={t('forms.city.label')} />
              </Form.Item>
            </FormInputTooltip>
            {/**
             * STATE
             */}
            <FormInputTooltip name="state" className="col-12 col-slg-6 mb-2 mb-sm-0">
              <Form.Item<TEyeviewUserField>
                name="state"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.stateValidationFn({
                        value,
                        errorMessage: t('forms.state.maxLength')
                      })
                  }
                ]}
              >
                <Input className={styles.addonData} addonBefore={t('forms.state.label')} />
              </Form.Item>
            </FormInputTooltip>
            {/**
             * ZIP
             */}
            <FormInputTooltip name="zip" className="col-12 col-slg-6">
              <Form.Item<TEyeviewUserField>
                name="zip"
                rules={[
                  {
                    validator: (_, value: string) =>
                      ValidationUtilities.zipValidationFn({
                        value,
                        errorMessage: t('forms.zip.maxLength')
                      })
                  }
                ]}
              >
                <Input className={styles.addonData} addonBefore={t('forms.zip.label')} />
              </Form.Item>
            </FormInputTooltip>
          </div>
        </Form.Item>
        {/**
         * LANGUAGE
         */}
        <Form.Item<TEyeviewUserField>
          label={
            <div className="d-flex align-items-center">
              <span className="text-truncate">{t('forms.language.label')}</span>
              <Tooltip title={t('forms.language.instruction')} placement="top" trigger="click">
                <div className="d-flex align-items-center cursor-pointer">
                  <MoreInfoIcon />
                </div>
              </Tooltip>
            </div>
          }
          name="languageId"
          className="mb-2"
        >
          <Select
            options={listLanguage.map((language) => {
              return {
                label: language.name,
                value: language.id
              };
            })}
            showSearch
            suffixIcon={<DropdownIcon />}
            optionFilterProp="children"
            filterOption={filterOption}
          />
        </Form.Item>
        {/**
         * COMMENT
         */}
        <FormInputTooltip name="comment">
          <Form.Item<TEyeviewUserField>
            label={t('forms.comment.label')}
            name="comment"
            className="mb-0"
            rules={[
              {
                validator: (_, value: string) =>
                  ValidationUtilities.commentValidationFn({
                    value,
                    errorMessage: t('forms.comment.maxLength')
                  })
              }
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </FormInputTooltip>
      </Section>
    );
  }
);
