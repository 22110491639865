import { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { useAppUtil } from 'context/UtilContext';

type Props = {
  isLoading: boolean;
  listAccounts: { accountName: string }[];
  onClickLogout: (accountName: string) => void;
};

function TableListEyeviewSessionByAccount({ isLoading, listAccounts, onClickLogout }: Props) {
  const { t } = useTranslation();
  const { openModal } = useAppUtil();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const columns: ColumnsType<{ accountName: string }> = useMemo(() => {
    return [
      {
        title: () => t('eyeviewSessionPage.table.columns.name'),
        dataIndex: 'accountName',
        key: 'accountName',
        render: (value) => {
          return value;
        },
        width: '20%'
      },
      {
        title: () => t('eyeviewSessionPage.table.columns.logout'),
        render: (val, record) => {
          return (
            <Button
              onClick={() => {
                if (isSubmitting) return;
                setIsSubmitting(true);
                openModal({
                  title: t('components.confirmationTitle'),
                  content: t('components.confirmationMessage', {
                    action: t('components.logout').toLowerCase(),
                    entity: t('header.account').toLowerCase()
                  }),
                  okText: `${t('components.ok')}`,
                  cancelText: `${t('components.cancel')}`,
                  onOk: () => {
                    onClickLogout(record.accountName);
                    setIsSubmitting(false);
                  },
                  onCancel: () => {
                    setIsSubmitting(false);
                  }
                });
              }}
              className="fs-14 fw-semibold rounded-5 px-4"
              style={{ border: '1px solid var(--sub-3)', color: 'var(--sub-3)' }}
            >
              {capitalize(t('components.logout'))}
            </Button>
          );
        }
      }
    ];
  }, [isSubmitting]);

  return (
    <Table
      loading={isLoading}
      rowKey={'accountName'}
      tableLayout="auto"
      dataSource={listAccounts}
      columns={columns}
      rowClassName={() => {
        return 'no-border';
      }}
      scroll={
        listAccounts.length > 0
          ? {
              x: 'auto'
            }
          : {}
      }
    />
  );
}

export default TableListEyeviewSessionByAccount;
