import { Form } from 'antd';
import { useAppUtil } from 'context/UtilContext';
import { EHttpStatusCode, EStatusInvalid, Routes } from 'enums';
import type { TCameraGroupType, TAccount } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CameraGroupRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';
import type { TCameraGroupGeneralFieldType } from '../cameraGroupPage.types';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_NUMBER } from 'constant';
import { orderBy } from 'lodash';
import { ApiErrorResponse } from 'models/ApiError';

export const useCameraGroupCreatePageController = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cameraGroupRepository = CameraGroupRepository(AxiosClient);

  const { openNotification } = useAppUtil();

  const [accountList, setAccountList] = useState<{
    data: TAccount[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });

  const [createCameraGroupForm] = Form.useForm();
  const [cameraGroupTypes, setCameraGroupTypes] = useState<{
    data: TCameraGroupType[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });

  const handleCreateCameraGroup = async (
    data: TCameraGroupGeneralFieldType,
    callback: () => void
  ) => {
    try {
      const { code } = await cameraGroupRepository.createCameraGroup({
        ...data,
        name: data.name.trim(),
        comment: data.comment ? data.comment.trim() : '',
        address: data.address ? data.address.trim() : undefined,
        latitude: data.latitude ? Number(data.latitude) : undefined,
        longitude: data.longitude ? Number(data.longitude) : undefined
      });
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.create')} ${t('cameraGroupPage.entity')}`,
          description: `${t('components.success')}`
        });
        const search = new URLSearchParams({
          search: data.name.toString().trim(),
          page: `${DEFAULT_PAGE_NUMBER}`
        });
        navigate(`${Routes.ECameraGroupRoutes.LISTING}?${search.toString()}`);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'create',
        entity: t('cameraGroupPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.create')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
    } finally {
      callback();
    }
  };

  const handleFetchListAccount = async () => {
    try {
      setAccountList((prev) => ({
        ...prev,
        loading: true
      }));
      const { code, data } = await cameraGroupRepository.getAllAccounts();
      if (code == EHttpStatusCode.OK) {
        setAccountList({
          data: data.filter((item: TAccount) => item.invalid === EStatusInvalid.VALID),
          loading: false
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('accountPage.entity')}`,
        description: message
      });
    } finally {
      setAccountList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleFetchCameraGroupTypeList = async () => {
    try {
      setCameraGroupTypes((prev) => ({
        ...prev,
        loading: true
      }));
      const { data, code } = await cameraGroupRepository.getListCameraGroupType();
      if (code === EHttpStatusCode.OK) {
        setCameraGroupTypes({
          data,
          loading: false
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupType.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupType.entity')}`,
        description: message
      });
    } finally {
      setCameraGroupTypes((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleCancelClick = useCallback(() => {
    navigate(
      {
        pathname: Routes.ECameraGroupRoutes.LISTING
      },
      { replace: true }
    );
  }, []);

  useEffect(() => {
    Promise.allSettled([handleFetchListAccount(), handleFetchCameraGroupTypeList()]);
  }, []);

  useEffect(() => {
    if (cameraGroupTypes.data.length > 0) {
      createCameraGroupForm.setFieldValue('cameraGroupTypeId', cameraGroupTypes.data[0].id);
    }
  }, [cameraGroupTypes]);

  useEffect(() => {
    if (accountList.data.length > 0) {
      // sort asc accountList first
      const sortedAccountList = orderBy(accountList.data, 'name', ['asc']);
      createCameraGroupForm.setFieldValue('accountId', sortedAccountList[0].id);
    }
  }, [accountList]);

  return {
    createCameraGroupForm,
    cameraGroupTypes,
    accountList,
    onFormSubmit: handleCreateCameraGroup,
    onCancelClick: handleCancelClick
  };
};
