import { useLoaderContext } from 'context/LoaderContext';
import { EHttpStatusCode, Routes } from 'enums';
import type { TAccount, TPermission } from 'models';
import { useEffect, useState } from 'react';
import { EyeviewUserRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';
import {
  type TEyeviewUserField,
  useEyeviewUserPageController
} from '../../EyeviewUserPageController';
import { useAppUtil } from 'context/UtilContext';
import { useEOSSContext } from 'context/EOSSContext';
import type { TPostEyeviewUserPayload } from 'repositories/EyeviewUser/EyeviewUserRepository';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import { ApiErrorResponse } from 'models/ApiError';
import { orderBy } from 'lodash';
import { DEFAULT_PAGE_NUMBER } from 'constant';

export const useCreateEyeviewUserPageController = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const eyeviewUserRepository = EyeviewUserRepository(AxiosClient);

  const { listLanguage } = useEOSSContext();

  const [createEyeviewUserForm] = useForm<TEyeviewUserField>();

  const { onFetchAccountList } = useEyeviewUserPageController();
  const { openNotification } = useAppUtil();
  const { listValidation } = useEOSSContext();
  const { loader } = useLoaderContext();

  const [selectedPermissions, setSelectedPermissions] = useState<TPermission[]>([]);

  const [permissionData, setPermissionData] = useState<{
    data: TPermission[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });
  const [accountList, setAccountList] = useState<{
    data: TAccount[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });

  const addPermission = (value: number) => {
    setSelectedPermissions((prev) => {
      const newPermission = [...prev];
      newPermission.push(permissionData.data.find((p) => p?.id === value) as TPermission);
      return newPermission;
    });
  };
  const removePermission = (value: number) => {
    setSelectedPermissions((prev) => prev.filter((p) => p.id !== value));
  };

  const handleGetEyeviewPermission = async () => {
    if (permissionData.loading) return;
    try {
      const { code, data } = await eyeviewUserRepository.getListEyeviewPermission();
      if (code === EHttpStatusCode.OK) {
        setPermissionData({
          data,
          loading: false
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('userPage.sections.permission.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('userPermissions.entity')}`,
        description: message
      });
    } finally {
      setPermissionData((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleCreateEyeviewUser = async (params: TPostEyeviewUserPayload) => {
    loader.start();
    try {
      const code = await eyeviewUserRepository.createEyeviewUser({
        ...params,
        permissionIds: selectedPermissions.map((p) => p.id).toString()
      });
      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.create')} ${t('userPage.entity')}`,
          description: `${t('components.success')}`
        });
        searchParams.set('search', params.username.trim());
        searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
        setSearchParams(searchParams);
        navigate({ pathname: Routes.EUserRoutes.LISTING, search: searchParams.toString() });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'create',
        entity: t('userPage.entity'),
        identifier: `username ${params.username}`,
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.create')} ${t('userPage.entity')}`,
        description: message
      });
    } finally {
      loader.complete();
    }
  };

  const handleCancelClick = () =>
    navigate(
      {
        pathname: Routes.EUserRoutes.LISTING,
        search: searchParams.toString()
      },
      { replace: true }
    );

  useEffect(() => {
    handleGetEyeviewPermission();
    setAccountList((prev) => ({
      ...prev,
      loading: true
    }));
    onFetchAccountList()
      .then(({ success, data }: { success: boolean; data: TAccount[] }) => {
        if (success) {
          setAccountList({
            data: orderBy(data, 'name', 'asc'),
            loading: false
          });
        }
      })
      .finally(() => {
        setAccountList((prev) => ({
          ...prev,
          loading: false
        }));
      });
  }, []);

  return {
    createEyeviewUserForm,
    selectedPermission: selectedPermissions,
    permissionData,
    accountList,
    listValidation,
    listLanguage,
    addPermission,
    removePermission,
    onCancel: handleCancelClick,
    onFormSubmit: handleCreateEyeviewUser
  };
};
