import { Form, Input, Result, Select } from 'antd';
import { ERoleKey, EStatusInvalid, Routes } from 'enums';
import { usePermission } from 'hooks/usePermission';
import { FormAction, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCreationDomainController, { DomainFieldType } from '../CreationDomainController';
import { DropdownIcon } from 'assets/icons';
import ValidationUtilities from 'utils/validationUtils';

type Props = {
  editMode?: boolean;
};

export const DomainGeneralPageView = ({ editMode = false }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { currentDomain, formGeneral, handleSubmit } = useCreationDomainController();

  if (!currentDomain && editMode) {
    return (
      <Result
        status="404"
        title={t('domainPage.404.title')}
        subTitle={t('domainPage.404.subTitle')}
      />
    );
  }

  const [searchParams] = useSearchParams();

  const filterOption = (input: string, option?: { label: string; value: string | number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <Form
        form={formGeneral}
        layout="horizontal"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 10 }}
        initialValues={{ invalid: EStatusInvalid.VALID }}
        onFinish={(value: DomainFieldType) => handleSubmit(value, editMode)}
        disabled={!usePermission(editMode ? ERoleKey.UPDATE : ERoleKey.CREATE).allowed}
      >
        <Section title={t('domainPage.sections.general.title')} classNameBody="px-4 pt-3 pb-4">
          <div>
            <Form.Item<DomainFieldType>
              name="domainName"
              label={t('forms.domainName.label')}
              colon={false}
              rules={[
                { message: t('forms.domainName.required'), required: true },
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.fullNameValidationFn({
                      value,
                      errorMessage: t('forms.domainName.pattern')
                    })
                }
              ]}
            >
              <Input id="input-domain-name" disabled={editMode} />
            </Form.Item>
            <Form.Item<DomainFieldType>
              name="invalid"
              label={t('forms.valid.label')}
              colon={false}
              rules={[
                {
                  required: usePermission(editMode ? ERoleKey.UPDATE : ERoleKey.CREATE).allowed,
                  message: t('forms.valid.required')
                }
              ]}
            >
              <Select
                id="select-domain-valid"
                filterOption={filterOption}
                optionFilterProp="children"
                suffixIcon={<DropdownIcon />}
                options={[
                  {
                    value: EStatusInvalid.VALID,
                    label: t('components.valid').toUpperCase()
                  },
                  {
                    value: EStatusInvalid.INVALID,
                    label: t('components.invalid').toUpperCase()
                  }
                ]}
              />
            </Form.Item>
            <Form.Item<DomainFieldType>
              name="comment"
              label={t('forms.comment.label')}
              colon={false}
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.commentValidationFn({
                      value,
                      errorMessage: t('forms.comment.maxLength')
                    })
                }
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </div>
        </Section>
        <FormAction
          CancelTypographyProps={{
            htmlType: 'reset'
          }}
          SubmitTypographyProps={{
            htmlType: 'submit'
          }}
          onCancel={() => {
            editMode
              ? navigate(
                  {
                    pathname: Routes.EDomainRoutes.LISTING,
                    search: searchParams.toString()
                  },
                  { replace: true }
                )
              : navigate({ pathname: Routes.EDomainRoutes.LISTING }, { replace: true });
          }}
          SubmitButtonPermission={{
            roleKey: editMode ? ERoleKey.UPDATE : ERoleKey.CREATE
          }}
        />
      </Form>
    </div>
  );
};
